import React, { useState, useEffect } from "react";

import { styled } from "@mui/joy/styles";
import Grid from "@mui/joy/Grid";
import { Solar, Lunar, HolidayUtil } from "./utils/lunar";
import { Table, Button, Autocomplete, Stack, Typography } from "@mui/joy";
import InputTime, { SelectRadio, AutoCurTime } from "./InputTime";
import {
  xunShouList,
  diPan,
  men,
  xing,
  xingList,
  diPanDiZhiList,
  jiaZiList,
  JieQiList,
  JiJuList,
  ganList,
  zhiList,
  xingZhuan,
  shengList,
  MaXing,
  wuXing2,
  wuXing,
  wuXingXh,
  menJian
} from "./utils/qimen";
import { Box } from "@mui/joy";

const QiMenItem = ({
  xuhao,
  baguai,
  dipangan,
  tianpangan,
  jiuxing,
  bamen,
  basheng,
  yuanjiuxing,
  yuanbamen,
}) => {
  // console.log('tianpangan',tianpangan);
  // console.log('dipangan',dipangan);
  return (
    <>
      <Grid
        container
        sx={{ flexGrow: 1 }}
        justifyContent="center"
        alignItems="center"
      >
        {baguai && (
          <Grid xs={12} >
            <Typography level="h5" align="center">
              {baguai[xuhao] || "中"}
              {"("}
              {xuhao}
              {")"}
            </Typography>
          </Grid>
        )}
        {basheng && (
          <Grid xs={12}>
            <Typography color="success" align="center">
              {basheng[xuhao]}
              {xuhao != 5 && "(神)"}
            </Typography>
          </Grid>
        )}

        {jiuxing && (
          <Grid xs={6}>
            <Typography align="center">
              {jiuxing[xuhao]}
              {xuhao != 5 &&
                "." + getXingWangXiangG (xuhao, jiuxing[xuhao]).state + ""}
            </Typography>
          </Grid>
        )}
        {tianpangan && (
          <Grid xs={6}>
            <Typography color="primary" align="center">
              {tianpangan[xuhao]}
              {xuhao != 5 && "(天)"}
            </Typography>
          </Grid>
        )}

        {bamen && (
          <Grid xs={6}>
            <Typography color="danger" align="center">
              {bamen[xuhao]}
              {xuhao != 5 &&
                "." + getMemWangXiangG(xuhao, bamen[xuhao]).state + ""}
            </Typography>
          </Grid>
        )}
        {dipangan && (
          <Grid xs={6}>
            <Typography color="warning" align="center">
              {dipangan[xuhao]}
              {"(地)"}
            </Typography>
          </Grid>
        )}

        {yuanjiuxing && (
          <Grid xs={12}>
            <Typography align="center">{yuanjiuxing[xuhao]}</Typography>
          </Grid>
        )}
        {yuanbamen && (
          <Grid xs={12}>
            <Typography align="center">{yuanbamen[xuhao]}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const QiMenTable = ({
  dipangan,
  tianpangan,
  jiuxing,
  bamen,
  basheng,
  yuanjiuxing,
  yuanbamen,
}) => {
  return (
    <>
      <Table style={{ border: "1px solid black" }} sx={{m:0}} borderAxis="both">
        <tbody>
          <tr>
            <td>
              <QiMenItem
                xuhao={4}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
            <td>
              <QiMenItem
                xuhao={9}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
            <td>
              <QiMenItem
                xuhao={2}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
          </tr>
          <tr>
            <td>
              <QiMenItem
                xuhao={3}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
            <td>
              <QiMenItem
                xuhao={5}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
            <td>
              <QiMenItem
                xuhao={7}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
          </tr>
          <tr>
            <td>
              <QiMenItem
                xuhao={8}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
            <td>
              <QiMenItem
                xuhao={1}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
            <td>
              <QiMenItem
                xuhao={6}
                baguai={diPan}
                dipangan={dipangan}
                tianpangan={tianpangan}
                jiuxing={jiuxing}
                bamen={bamen}
                basheng={basheng}
                yuanjiuxing={yuanjiuxing}
                yuanbamen={yuanbamen}
              ></QiMenItem>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
const getJieQi = (yyyy, mm, dd) => {
  mm = mm - 1;
  let year = yyyy;
  let month = mm;
  let day = dd;
  let sTermInfo = new Array(
    0,
    21208,
    42467,
    63836,
    85337,
    107014,
    128867,
    150921,
    173149,
    195551,
    218072,
    240693,
    263343,
    285989,
    308563,
    331033,
    353350,
    375494,
    397447,
    419210,
    440795,
    462224,
    483532,
    504758
  );
  let solarTerm = JieQiList;
  var solarTerms = "";

  while (solarTerms == "") {
    var tmp1 = new Date(
      31556925974.7 * (yyyy - 1900) +
        sTermInfo[mm * 2 + 1] * 60000 +
        Date.UTC(1900, 0, 6, 2, 5)
    );
    var tmp2 = tmp1.getUTCDate();
    if (tmp2 == dd) solarTerms = solarTerm[mm * 2 + 1];

    tmp1 = new Date(
      31556925974.7 * (yyyy - 1900) +
        sTermInfo[mm * 2] * 60000 +
        Date.UTC(1900, 0, 6, 2, 5)
    );
    tmp2 = tmp1.getUTCDate();
    if (tmp2 == dd) solarTerms = solarTerm[mm * 2];
    year = yyyy;
    month = mm + 1;
    day = dd;
    if (dd > 1) {
      dd = dd - 1;
    } else {
      mm = mm - 1;
      if (mm < 0) {
        yyyy = yyyy - 1;
        mm = 11;
      }
      dd = 31;
    }
  }
  return { jieQi: solarTerms, date: { year, month, day } };
};
// 判断阴遁 还是阳遁
const yinYang = (jieqi) => {
  if (JieQiList.indexOf(jieqi) >= 11 && JieQiList.indexOf(jieqi) < 23)
    return { type: -1, name: "阴遁", shunxun: "逆排" };
  return { type: 1, name: "阳遁", shunxun: "顺排" };
};
// 时间比较
const diffdays = (jieqidate, qijudate) => {
  const startdate = new Date(jieqidate.year, jieqidate.month, jieqidate.day);
  const enddate = new Date(qijudate.year, qijudate.month, qijudate.day);
  const diffInMs = Math.abs(enddate - startdate);
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  return diffInDays;
};

// 起局：判断 阳遁 还是阴遁 第几局
// 节气名，节气时间，起局时间
const getQiJu = (jieqi, jieqidate, qijudate) => {
  const yinyang = yinYang(jieqi);

  const days = diffdays(jieqidate, qijudate) + 1;
  const jiju = JiJuList[JieQiList.indexOf(jieqi)];
  if (days < 5)
    return { yinyang, junum: jiju.ju[0], days, yuan: 0, yuantitle: "上元" };
  if (days >= 5 && days < 10)
    return { yinyang, junum: jiju.ju[1], days, yuan: 1, yuantitle: "中元" };
  return { yinyang, junum: jiju.ju[2], days, yuan: 2, yuantitle: "下元" };
};
// 布奇子
const getQizi = (type, qijunum) => {
  const qizi = {};

  let index = qijunum;
  for (let i = 0; i < 9; i++) {
    qizi[index] = diPanDiZhiList[i];

    if (type == 1) {
      index = index + 1;
      if (index > 9) index = 1;
    } else {
      index = index - 1;
      if (index < 1) index = 9;
    }
  }
  console.log("qijunum", qijunum);
  console.log("qizi", qizi);
  qizi[2] = qizi[2] + qizi[5];
  return qizi;
};
// 旬首 空亡
const getkongwang = (zhi) => {
  let shizhindex = zhiList.indexOf(zhi);
  let kongwang = "";
  shizhindex = shizhindex - 1;
  if (shizhindex < 0) shizhindex = 11;
  kongwang = zhiList[shizhindex];

  shizhindex = shizhindex - 1;
  if (shizhindex < 0) shizhindex = 11;
  kongwang = kongwang + zhiList[shizhindex];
  return kongwang;
};
// 找旬首
const getxunshou = (shigan, shizhi, dipangan) => {
  const ganindex = ganList.indexOf(shigan);
  const zhiindex = zhiList.indexOf(shizhi);
  let shouzhiindex = zhiindex - ganindex;
  if (shouzhiindex < 0) shouzhiindex = shouzhiindex + 12;
  const xunshou = "甲" + zhiList[shouzhiindex];
  const kongwang = getkongwang(zhiList[shouzhiindex]);
  const qizi = xunShouList[xunshou];
  let lougong = 1;
  let find=false;
  for (let index = 1; index <= 8; index++) {
    const element = dipangan[index].slice(0, 1);
    console.log('找到落宫：',index,qizi,element)
    if (element == qizi) {
      console.log('找到落宫：',index);
      find=true;
      lougong = index;
      break;
    }
  }
 if (!find) console.error('没找到旬首落宫', qizi);
  console.log({
    xunshou: xunshou,
    qizi: qizi,
    lougong,
    lougongname: diPan[lougong],
    ganindex,
    kongwang,
  });
  return {
    xunshou: xunshou,
    qizi: qizi,
    lougong,
    lougongname: diPan[lougong],
    ganindex,
    kongwang,
  };
};
// 布九星 值符宫位，时干宫位（1-8）
/*
  甲午时到壬寅时一共过了8个时辰
  所以值使门宫位要跳转8次。
  因为是阴遁局，所以要逆转8 次,阳遁顺转8次
*/
// 布九星 值符宫位，时干宫位（1-8）
const buJiuXing = (zhifugong, shigangong) => {
  const zhifuindex = xingZhuan.indexOf(zhifugong);
  const shiganindex = xingZhuan.indexOf(shigangong);
  let diff = zhifuindex - shiganindex;
  if (diff < 0) diff = diff + 8;
  const newXing = {};
  for (let index = 0; index < 8; index++) {
    let newindex = diff + index;
    if (newindex > 7) newindex = newindex - 8;
    const element = xingZhuan[newindex];

    // newXing.push(element);
    newXing[xingZhuan[index]] = xing[element];
  }
  console.log("newXing", newXing);
  return newXing;
};
// 布天盘，和九星一起转
const buTianPan = (zhifugong, shigangong, dipangan) => {
  const zhifuindex = xingZhuan.indexOf(zhifugong);
  const shiganindex = xingZhuan.indexOf(shigangong);
  let diff = zhifuindex - shiganindex;
  if (diff < 0) diff = diff + 8;
  const newTianpan = {};
  for (let index = 0; index < 8; index++) {
    let newindex = diff + index;
    if (newindex > 7) newindex = newindex - 8;
    const element = xingZhuan[newindex];

    // newXing.push(element);
    newTianpan[xingZhuan[index]] = dipangan[element];
  }
  console.log("newTianpan", newTianpan);
  // newTianpan[2]=newTianpan[2]+newTianpan[5];
  return newTianpan;
};

const buBaMen = (shizhi, qiju, lougong) => {
  let shizhindex = ganList.indexOf(shizhi);
  console.log("shizhindex", shizhindex);
  if (shizhindex > 8) shizhindex = shizhindex - 9;

  if (qiju.yingyan != 1) {
    shizhindex = 9 - shizhindex;
  }
  shizhindex = shizhindex + lougong;

  if (shizhindex > 8) shizhindex = shizhindex - 9;
  if (shizhindex < 0) shizhindex = 9 - shizhindex;

  let diff = shizhindex;
  // 如是5 寄坤二宫
  
  if (diff == 5) {
    diff = 2;
  }
  

 // console.log("值使门宫位：", diff); // 第二宫 转到1宫  计算需要转几次
const endindex = xingZhuan.indexOf(diff);
const startindex = xingZhuan.indexOf(lougong);
 // console.log("值使门宫位，数字位置", endindex);
  const newMen = {};
  
  let zhuannum= endindex-startindex;
  if (zhuannum < 0) zhuannum = zhuannum + 8;
  console.log("跳转次数：", zhuannum);

  for (let index = 0; index < 8; index++) {
    let newindex = zhuannum + index;
    if (newindex > 7) newindex = newindex - 8;

    const element = xingZhuan[index];
    newMen[xingZhuan[newindex]] = menJian[element]; // men  menJian 简称
  }
  console.log("newMen", newMen);
  return {newMen,menluogong:diff,mentiaonum:zhuannum};
};
//  布八神
const buBaSheng = (zhifugong, qiju) => {
  const diff = xingZhuan.indexOf(zhifugong); //找到值符开始宫位索引
  const newSheng = {};
  let index = diff;
  for (let i = 0; i < 8; i++) {
    newSheng[xingZhuan[index]] = shengList[i];

    if (qiju.yingyan == 1) {
      // 顺排
      index = index + 1;
      if (index > 7) index = 1;
    } else {
      index = index - 1;
      if (index < 0) index = 7;
    }
  }
  console.log("newSheng", newSheng);
  return newSheng;
};
// 旺相状态
const getWangXiangZT = (my, ta, jiuxing) => {
  const diff = my - ta;
  if (jiuxing) {
    if (diff == 0) return { xh: 1, state: "相" };
    if (diff == 1 || diff == -4) return { xh: 2, state: "旺" };
  } else {
    if (diff == 0) return { xh: 1, state: "旺" };
    if (diff == 1 || diff == -4) return { xh: 1, state: "相" };
  }

  if (diff == -1 || diff == 4) return { xh: 0, state: "休" };

  if (jiuxing) {
    if (diff == -2 || diff == 3) return { xh: -1, state: "囚" };
    if (diff == 2 || diff == -3) return { xh: -2, state: "死" };
  } else {
    if (diff == -2 || diff == 3) return { xh: -1, state: "囚.迫" };
    if (diff == 2 || diff == -3) return { xh: -2, state: "死.制" };
  }
};
// 返回门在宫位旺相 参数 宫序号，门名称
const getMemWangXiangG = (gongxh, menname) => {
  let yuanmenindex = 1;
  for (let index = 1; index <= 9; index++) {
    if (index == 5) continue;
    const element = men[index];
    if (menname == element) yuanmenindex = index;
  }
  return getWangXiangZT(wuXing2[yuanmenindex], wuXing2[gongxh]);
};

// 返回门在宫位旺相 参数 宫序号，星名称
const getXingWangXiangG = (gongxh, xingname) => {
  let yuanxingindex = 1;
  for (let index = 1; index <= 9; index++) {
    const element = xing[index];
    if (xingname == element) yuanxingindex = index;
  }
  return getWangXiangZT(wuXing2[yuanxingindex], wuXing2[gongxh], true);
};

function Qimen() {
  const [qijutype, setqijutype] = useState(1);

  // 当前时间
  const [curtime, setCurTime] = useState(new Date());
  const [isRunning, setIsRunning] = useState(true);

  // 马星
  const [maxing, setmaxing] = useState("");
  // 空亡
  const [kongwang, setkongwang] = useState("");

  const [xunshou, setXunshou] = useState({
    xunshou: "",
    qizi: "",
    lougong: 1,
    ganindex: 0,
  });
  // 九星
  const [jiuXing, setjiuXing] = useState({});
  // 八门
  const [baMen, setbaMen] = useState({});
  // 八神
  const [basheng, setbaSheng] = useState({});
  // 值符宫
  const [zhifugong, setzhiFugong] = useState(1);
  // 值符星
  const [zhifuxing, setzhiFuxing] = useState("");
  // 值使门
  const [zhishimen, setzhiShimem] = useState("");
  // 值使门落宫和调整次数
    const [zhishimenluo, setzhiShimenluo] = useState({menluogong:0,mentiaonum:0});
  //地盘奇子
  const [dipanGan, setDipanGan] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
  });
  //天盘奇子
  const [tianpanGan, setTianpanGan] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
  });
  const [time, setTime] = useState({
    year: 2020, // 1993
    month: 7, // 7
    day: 23, // 20
    hour: 4, // 22
    min: 0,
    sec: 0,
  });
  const [inputtime, setInputTime] = useState(time);

  const [lunar, setLunar] = useState(
    Solar.fromYmdHms(
      time.year,
      time.month,
      time.day,
      time.hour,
      time.min,
      time.sec
    ).getLunar()
  );
  // 节气
  const [jieqi, setJieqi] = useState(getJieQi(time.year, time.month, time.day));
  // 起局
  // { yinyang, junum: jiju.ju[2], days, yuan: 2, yuantitle: '下元' }
  const [qiju, setQiJu] = useState(getQiJu(jieqi.jieQi, jieqi.date, time));
  const [bz, setBz] = useState(
    Solar.fromYmdHms(
      time.year,
      time.month,
      time.day,
      time.hour,
      time.min,
      time.sec
    )
      .getLunar()
      .getEightChar()
  );

  const handleQiJuClick = () => {
    if (qijutype == 1) {
      setTime({
        year: curtime.getFullYear(), // 1993
        month: curtime.getMonth() + 1, // 7
        day: curtime.getDate(), // 20
        hour: curtime.getHours(), // 22
        min: curtime.getMinutes(),
        sec: 0,
      });
    } else {
      setTime(Object.assign({}, inputtime));
    }
  };

  useEffect(() => {
    // 阴历对象
    setLunar(
      Solar.fromYmdHms(
        time.year,
        time.month,
        time.day,
        time.hour,
        time.min,
        time.sec
      ).getLunar()
    );
    // 节气对象
    setJieqi(getJieQi(time.year, time.month, time.day));

    // 八字
    setBz(
      Solar.fromYmdHms(
        time.year,
        time.month,
        time.day,
        time.hour,
        time.min,
        time.sec
      )
        .getLunar()
        .getEightChar()
    );
  }, [time]);
  useEffect(() => {
    setQiJu(getQiJu(jieqi.jieQi, jieqi.date, time));
  }, [jieqi, time]);
  useEffect(() => {
    setDipanGan(getQizi(qiju.yinyang.type, qiju.junum));
  }, [qiju]);
  useEffect(() => {
    const lxunshou = getxunshou(bz.getTimeGan(), bz.getTimeZhi(), dipanGan);
    setXunshou(lxunshou);
    // dipanGan. bz.getTimeGan()
    const shigan = bz.getTimeGan();
    //找时干宫位
    let shigangong = 1;
    for (let index = 1; index <= 9; index++) {
      const element = dipanGan[index].slice(0, 1); // 第5宫有寄宫，只能取第一个字符
      // console.log('element',element);
      if (element == shigan) {
        shigangong = index;
        break;
      }
    }
    setzhiFugong(shigangong);
    setzhiFuxing(xing[lxunshou.lougong]);
    setzhiShimem(men[lxunshou.lougong]);
    console.log("xunshou.lougong", lxunshou.lougong, "shigangong", shigangong);
    // 九星
    setjiuXing(buJiuXing(lxunshou.lougong || 1, shigangong));
    setTianpanGan(buTianPan(lxunshou.lougong || 1, shigangong, dipanGan));
    const newbamen= buBaMen(bz.getTimeGan(), qiju, lxunshou.lougong)
    setbaMen(newbamen.newMen);
    // 设置值使门落宫和跳转次数
    setzhiShimenluo({menluogong:newbamen.menluogong,mentiaonum:newbamen.mentiaonum});

    setbaSheng(buBaSheng(shigangong, qiju));
    // 获得马星
    setmaxing(MaXing[bz.getTimeZhi()]);
    // 设置空亡
    setkongwang(lxunshou.kongwang);
  }, [bz, dipanGan]);

  return (
    <>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12} md={3}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <SelectRadio type={qijutype} setType={setqijutype}></SelectRadio>
          </Stack>
        </Grid>
        {qijutype == 2 && (
          <Grid xs={12} md={6}>
            <InputTime time={inputtime} setTime={setInputTime}></InputTime>
          </Grid>
        )}
        {qijutype == 1 && (
          <Grid xs={12} md={6}>
            <AutoCurTime
              curtime={curtime}
              setCurTime={setCurTime}
            ></AutoCurTime>
          </Grid>
        )}
        <Grid xs={12} md={3}>
          <Button sx={{ width: 220 }} size="lg" onClick={handleQiJuClick}>
            开始起局
          </Button>
        </Grid>

        <Grid xs={12}>
          <Table style={{ border: "1px solid black" }} borderAxis="both">
            <tbody>
              <tr>
                <td>
                  起局时间：{time.year}年{time.month}月{time.day}日{time.hour}时
                </td>
                <td>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    四柱：
                    <Stack>
                      <Box>
                        {" "}
                        {bz.getYearGan()} {bz.getMonthGan()} {bz.getDayGan()}{" "}
                        {bz.getTimeGan()}
                      </Box>
                      <Box>
                        {bz.getYearZhi()} {bz.getMonthZhi()} {bz.getDayZhi()}{" "}
                        {bz.getTimeZhi()}
                      </Box>
                    </Stack>
                  </Stack>
                </td>
              </tr>

              <tr>
                <td>
                  {"节气："}
                  {jieqi.jieQi} {jieqi.date.year}
                  {"-"}
                  {jieqi.date.month}
                  {"-"}
                  {jieqi.date.day}
                </td>
                <td>
                  {"起局："} {qiju.yinyang.name} {qiju.yinyang.shunxun} {"第"}
                  {qiju.days}
                  {"天"} {qiju.yuantitle}
                  {"第"}
                  {qiju.junum}
                  {"局"}
                </td>
              </tr>

              <tr>
                <td>
                  旬首：{xunshou.xunshou}
                  {"-"}
                  {xunshou.qizi} {"值符值使宫位:"} {xunshou.lougongname}
                  {"("}
                  {xunshou.lougong}
                  {")"}
                </td>
                <td>
                  值符星：{zhifuxing} {" 值符宫:"} {diPan[zhifugong]}
                  {"("}
                  {zhifugong}
                  {")"} {" 值使门:"} {zhishimen} {diPan[zhishimenluo.menluogong]}
                  {"("}
                  {zhishimenluo.menluogong}
                  {")"} {" 跳转数:"}{zhishimenluo.mentiaonum}
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography level="h7">排盘信息 </Typography>{" "}
            <Typography color="success">
              {" 马星:"}
              {maxing}
            </Typography>{" "}
            <Typography color="danger">
              {"空亡:"}
              {kongwang}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <QiMenTable
            jiuxing={jiuXing}
            basheng={basheng}
            bamen={baMen}
            dipangan={dipanGan}
            tianpangan={tianpanGan}
          ></QiMenTable>
        </Grid>
        <Grid xs={12}>
          <Box>九星和八门原始宫位</Box>
        </Grid>
        <Grid xs={12}>
          <QiMenTable
            dipangan={dipanGan}
            yuanjiuxing={xing}
            yuanbamen={men}
          ></QiMenTable>
        </Grid>
      </Grid>
    </>
  );
}

export default Qimen;
