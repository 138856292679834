// 旬首
const xunShouList = {
    '甲子': '戊',
    '甲戌': '己',
    '甲申': '庚',
    '甲午': '辛',
    '甲辰': '壬',
    '甲寅': '癸',
};

// 地盘
const diPan = {
    '6': '乾',
    '1': '坎',
    '8': '艮',
    '3': '震',
    '4': '巽',
    '9': '离',
    '2': '坤',
    '7': '兑',
};
// 马星
const MaXing = {
    '申': '寅',
    '子': '寅',
    '辰': '寅',
    '寅': '申',
    '午': '申',
    '戌': '申',
    '亥': '巳',
    '卯': '巳',
    '未': '巳',
    '巳': '亥',
    '酉': '亥',
    '丑': '亥',
};

// 八门 简称
const menJian = {
    '6': '开',
    '1': '休',
    '8': '生',
    '3': '伤',
    '4': '杜',
    '9': '景',
    '2': '死',
    '7': '惊',
};

// 八门
const men = {
    '6': '开门',
    '1': '休门',
    '8': '生门',
    '3': '伤门',
    '4': '杜门',
    '9': '景门',
    '2': '死门',
    '7': '惊门',
};
// 九星
const xing = {
    '6': '天心',
    '1': '天蓬',
    '8': '天任',
    '3': '天冲',
    '4': '天辅',
    '9': '天英',
    '2': '天芮',
    '7': '天柱',
    '5': '天禽',
};
// 宫位五行 九星和八门五行，
const wuXing = {
    '6': '金',
    '1': '水',
    '8': '土',
    '3': '木',
    '4': '木',
    '9': '火',
    '2': '土',
    '7': '水',
    '5': '土',
};
// 宫位五行 九星和八门五行，编号
const wuXing2 = {
    '6': 4,
    '1': 5,
    '8': 3,
    '3': 1,
    '4': 1,
    '9': 2,
    '2': 3,
    '7': 5,
    '5': 3,
};
// 五行编号
const wuXingXh = {
    '1': '木',
    '2': '火',
    '3': '土',
    '4': '金',
    '5': '水',
};
// 新盘转顺序
const xingZhuan = [6,1,8,3,4,9,2,7];

const xingList = [
    '天心',
    '天蓬',
    '天任',
    '天冲',
    '天辅',
    '天英',
    '天芮',
    '天柱',
];
const diPanDiZhiList = ['戊', '己', '庚', '辛', '壬', '癸', '丁', '丙', '乙'];

const jiaZiList = [
    '甲子', '乙丑', '丙寅', '丁卯', '戊辰',
    '己巳', '庚午', '辛未', '壬申', '癸酉',

    '甲戌', '乙亥', '丙子', '丁丑', '戊寅',
    '己卯', '庚辰', '辛巳', '壬午', '癸未',
    
    '甲申', '乙酉', '丙戌', '丁亥', '戊子',
    '己丑', '庚寅', '辛卯', '壬辰', '癸巳',
    
    '甲午', '乙未', '丙申', '丁酉', '戊戌',
    '己亥', '庚子', '辛丑', '壬寅', '癸卯',
    
    '甲辰', '乙巳', '丙午', '丁未', '戊申',
    '己酉', '庚戌', '辛亥', '壬子', '癸丑',
    
    '甲寅', '乙卯', '丙辰', '丁巳', '戊午',
    '己未', '庚申', '辛酉', '壬戌', '癸亥'
];
let JiJuList=[
    {title: '小寒', ju:[2,8,5]},
    {title: '大寒', ju:[3,9,6]},
    {title: '立春', ju:[8,5,2]},
    {title: '雨水', ju:[9,6,3]},
    {title: '惊蛰', ju:[1,7,4]},
    {title: '春分', ju:[3,9,6]},
    {title: '清明', ju:[4,1,7]},
    {title: '谷雨', ju:[5,2,8]},
    {title: '立夏', ju:[4,1,7]},
    {title: '小满', ju:[5,2,8]},
    {title: '芒种', ju:[6,3,9]},

    {title: '夏至', ju:[9,3,6]},
    {title: '小暑', ju:[8,2,5]},
    {title: '大暑', ju:[7,1,4]},
    {title: '立秋', ju:[2,5,8]},
    {title: '处暑', ju:[1,4,7]},
    {title: '白露', ju:[9,3,6]},
    {title: '秋分', ju:[7,1,4]},

    {title: '寒露', ju:[6,9,3]},
    {title: '霜降', ju:[5,8,2]},
    {title: '立冬', ju:[6,9,3]},
    {title: '小雪', ju:[5,8,2]},
    {title: '大雪', ju:[4,7,1]},

    {title: '冬至', ju:[1,7,4]},
]
let JieQiList= [
    '小寒',
    '大寒',
    '立春',
    '雨水',
    '惊蛰',
    '春分',
    '清明',
    '谷雨',
    '立夏',
    '小满',
    '芒种',
    '夏至',

    '小暑',
    '大暑',
    '立秋',
    '处暑',
    '白露',
    '秋分',
    '寒露',
    '霜降',
    '立冬',
    '小雪',
    '大雪',
    '冬至',
  ]
  let ganList= ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸'];
  /**
   * 十二地支 char of DiZhi
   */
   let zhiList= ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥'];

   let shengList= ['值符', '腾蛇', '太阴', '六合', '白虎', '玄武', '九地', '九天'];

module.exports = {
    xunShouList,
    diPan,
    men,
    menJian,
    xing,
    xingList,
    diPanDiZhiList,
    jiaZiList,
    JieQiList,
    JiJuList,
    ganList,
    zhiList,
    xingZhuan,
    shengList,
    MaXing,
    wuXing2,
    wuXing,
    wuXingXh

};