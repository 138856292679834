// 天干合
import { Solar, Lunar, HolidayUtil, LunarYear } from "./lunar";
let ganList = ["甲", "乙", "丙", "丁", "戊", "己", "庚", "辛", "壬", "癸"];
// 推算月支天干
const monthGanList = {
  甲: "丙",
  已: "丙",
  乙: "戊",
  庚: "戊",
  丙: "庚",
  辛: "庚",
  丁: "壬",
  壬: "壬",
  戊: "甲",
  癸: "甲",
};
///六十甲子
const gzList = [
  "甲子",
  "乙丑",
  "丙寅",
  "丁卯",
  "戊辰",
  "己巳",
  "庚午",
  "辛未",
  "壬申",
  "癸酉",
  "甲戌",
  "乙亥",
  "丙子",
  "丁丑",
  "戊寅",
  "己卯",
  "庚辰",
  "辛巳",
  "壬午",
  "癸未",
  "甲申",
  "乙酉",
  "丙戌",
  "丁亥",
  "戊子",
  "己丑",
  "庚寅",
  "辛卯",
  "壬辰",
  "癸巳",
  "甲午",
  "乙未",
  "丙申",
  "丁酉",
  "戊戌",
  "己亥",
  "庚子",
  "辛丑",
  "壬寅",
  "癸卯",
  "甲辰",
  "乙巳",
  "丙午",
  "丁未",
  "戊申",
  "己酉",
  "庚戌",
  "辛亥",
  "壬子",
  "癸丑",
  "甲寅",
  "乙卯",
  "丙辰",
  "丁巳",
  "戊午",
  "己未",
  "庚申",
  "辛酉",
  "壬戌",
  "癸亥",
];
// 禄判断
const LuList = {
  甲: "寅",
  乙: "卯",
  丙: "巳",
  戊: "巳",
  丁: "午",
  己: "午",
  庚: "申",
  辛: "酉",
  壬: "亥",
  癸: "子",
};
// 桃花
//年支或日支是:寅、午、戌， 见到卯即是桃花。

//年支或日支是:申、子、辰， 见到酉即是桃花。

//年支或日支是:亥、卯、未， 见到子即是桃花。

//年支或日支是:巳、酉、丑，见到午便是桃花。
const TaoHuaList = {
  寅: "卯",
  午: "卯",
  戌: "卯",

  申: "酉",
  子: "酉",
  辰: "酉",

  亥: "子",
  卯: "子",
  未: "子",

  巳: "午",
  酉: "午",
  丑: "午",
};
// 羊刃
// 甲羊刃在卯，乙羊刃在寅，丙戊羊刃在午，丁己羊刃在巳，庚羊刃在酉，辛羊刃在申，壬羊刃在子，癸羊刃在亥。
const YanRenList = {
  甲: "卯",
  乙: "寅",
  丙: "午",
  戊: "午",
  丁: "巳",
  己: "巳",
  庚: "酉",
  辛: "申",
  壬: "子",
  癸: "亥",
};

export const ShiShengJCList = {
  偏印: "枭",
  正印: "印",
  偏财: "才",
  正财: "财",
  食神: "食",
  伤官: "伤",
  比肩: "比",
  劫财: "劫",
  七杀: "杀",
  正官: "官",
};

/**
 * 十二地支 char of DiZhi
 */
let zhiList = [
  "子",
  "丑",
  "寅",
  "卯",
  "辰",
  "巳",
  "午",
  "未",
  "申",
  "酉",
  "戌",
  "亥",
];

const ZhangShengList = [
  "长生",
  "沐浴",
  "冠带",
  "临官",
  "帝旺",
  "衰",
  "病",
  "死",
  "墓库",
  "绝",
  "胎",
  "养",
];
// 长生位置
const ZhangShengListSet = [
  { name: "甲", start: "亥", yingyan: 1 },
  { name: "乙", start: "午", yingyan: -1 },
  { name: "丙", start: "寅", yingyan: 1 },
  { name: "丁", start: "酉", yingyan: -1 },
  { name: "戊", start: "寅", yingyan: 1 },
  { name: "己", start: "酉", yingyan: -1 },
  { name: "庚", start: "巳", yingyan: 1 },
  { name: "辛", start: "子", yingyan: -1 },
  { name: "壬", start: "申", yingyan: 1 },
  { name: "癸", start: "卯", yingyan: -1 },
];
// 得到十二长生
export const getZhangSheng = (gan, zhi) => {
  if (!gan || !zhi) return "";
  console.log("gan, zhi", gan, zhi, ganList.indexOf(gan));
  const zhangshengset = ZhangShengListSet[ganList.indexOf(gan)];
  const zhangshengzhiindex = zhiList[zhangshengset.start]; //得到长生位置
  let zhiindex = zhiList[zhi];
  if (zhangshengset.yingyan == -1) zhiindex = 12 - zhiindex;
  let zhangsheng = zhiindex - zhangshengzhiindex;
  if (zhangsheng < 0) zhangsheng = zhangsheng + 12;
  return ZhangShengList[zhangsheng];
};

const GanHeList = [
  {
    item1: "甲",
    item2: "己",
    desc: "甲己合化土",
    name: "合",
    wuxing: "土",
    zhigan: "gan",
    type: "he",
    subtype: "5he",
  },
  {
    item1: "乙",
    item2: "庚",
    desc: "乙庚合化金",
    name: "合",
    wuxing: "金",
    zhigan: "gan",
    type: "he",
    subtype: "5he",
  },
  {
    item1: "丙",
    item2: "辛",
    desc: "丙辛合化水",
    name: "合",
    wuxing: "水",
    zhigan: "gan",
    type: "he",
    subtype: "5he",
  },
  {
    item1: "丁",
    item2: "壬",
    desc: "丁壬合化木",
    name: "合",
    wuxing: "木",
    zhigan: "gan",
    type: "he",
    subtype: "5he",
  },
  {
    item1: "戊",
    item2: "癸",
    desc: "戊癸合化火",
    name: "合",
    wuxing: "火",
    zhigan: "gan",
    type: "he",
    subtype: "5he",
  },
];
// 地支合
const ZhiHe6List = [
  {
    item1: "子",
    item2: "丑",
    desc: "子丑合化土",
    name: "合",
    wuxing: "土",
    zhigan: "zhi",
    type: "he",
    subtype: "6he",
  },
  {
    item1: "寅",
    item2: "亥",
    desc: "寅亥合化木",
    name: "合",
    wuxing: "木",
    zhigan: "zhi",
    type: "he",
    subtype: "6he",
  },
  {
    item1: "卯",
    item2: "戌",
    desc: "卯戌合化火",
    name: "合",
    wuxing: "火",
    zhigan: "zhi",
    type: "he",
    subtype: "6he",
  },
  {
    item1: "辰",
    item2: "酉",
    desc: "辰酉合化金",
    name: "合",
    wuxing: "金",
    zhigan: "zhi",
    type: "he",
    subtype: "6he",
  },
  {
    item1: "巳",
    item2: "申",
    desc: "巳申合化水",
    name: "合",
    wuxing: "水",
    zhigan: "zhi",
    type: "he",
    subtype: "6he",
  },
  {
    item1: "午",
    item2: "未",
    desc: "午未合化火或土",
    name: "合",
    wuxing: "火或土",
    zhigan: "zhi",
    type: "he",
    subtype: "6he",
  },
];
// 地支合
const ZhiHe3List = [
  {
    item1: "申",
    item2: "子",
    item3: "辰",
    desc: "申、子、辰合化水局",
    name: "三合",
    name2: "半合",
    wuxing: "水",
    zhigan: "zhi",
    type: "he",
    subtype: "3he",
    bantype: "banhe",
  },
  {
    item1: "亥",
    item2: "卯",
    item3: "未",
    desc: " 亥、卯、未合化木局",
    name: "三合",
    name2: "半合",
    wuxing: "木",
    zhigan: "zhi",
    type: "he",
    subtype: "3he",
    bantype: "banhe",
  },
  {
    item1: "寅",
    item2: "午",
    item3: "戌",
    desc: "寅、午、戌合化火局",
    name: "三合",
    name2: "半合",
    wuxing: "火",
    zhigan: "zhi",
    type: "he",
    subtype: "3he",
    bantype: "banhe",
  },
  {
    item1: "巳",
    item2: "酉",
    item3: "丑",
    desc: "巳、酉、丑合化金局",
    name: "三合",
    name2: "半合",
    wuxing: "金",
    zhigan: "zhi",
    type: "he",
    subtype: "3he",
    bantype: "banhe",
  },
];
// 天干冲 甲庚相冲、乙辛相冲、壬丙相冲、癸丁相冲
const GanChongList = [
  {
    item1: "甲",
    item2: "庚",
    desc: "甲庚相冲",
    name: "冲",
    zhigan: "gan",
    type: "chong",
    subtype: "4chong",
  },
  {
    item1: "乙",
    item2: "辛",
    desc: "乙辛相冲",
    name: "冲",
    zhigan: "gan",
    type: "chong",
    subtype: "4chong",
  },
  {
    item1: "壬",
    item2: "丙",
    desc: "壬丙相冲",
    name: "冲",
    zhigan: "gan",
    type: "chong",
    subtype: "4chong",
  },
  {
    item1: "癸",
    item2: "丁",
    desc: "癸丁相冲",
    name: "冲",
    zhigan: "gan",
    type: "chong",
    subtype: "4chong",
  },
];

// 地支冲
const zhiChongList = [
  {
    item1: "子",
    item2: "午",
    desc: "子午相冲",
    name: "冲",
    zhigan: "zhi",
    type: "chong",
    subtype: "6chong",
  },
  {
    item1: "丑",
    item2: "未",
    desc: "丑未相冲",
    name: "冲",
    zhigan: "zhi",
    type: "chong",
    subtype: "6chong",
  },
  {
    item1: "寅",
    item2: "申",
    desc: "寅申相冲",
    name: "冲",
    zhigan: "zhi",
    type: "chong",
    subtype: "6chong",
  },

  {
    item1: "卯",
    item2: "酉",
    desc: "卯酉相冲",
    name: "冲",
    zhigan: "zhi",
    type: "chong",
    subtype: "6chong",
  },
  {
    item1: "辰",
    item2: "戌",
    desc: "辰戌相冲",
    name: "冲",
    zhigan: "zhi",
    type: "chong",
    subtype: "6chong",
  },
  {
    item1: "巳",
    item2: "亥",
    desc: "巳亥相冲",
    name: "冲",
    zhigan: "zhi",
    type: "chong",
    subtype: "6chong",
  },
];

// 地支刑
const zhiXingList = [
  {
    item1: "子",
    item2: "卯",
    desc: "子卯相刑,无礼之刑",
    name: "刑",
    zhigan: "zhi",
    type: "xing",
    subtype: "5xing",
  },
  {
    item1: "辰",
    item2: "辰",
    desc: "辰辰自刑",
    name: "刑",
    zhigan: "zhi",
    type: "xing",
    subtype: "5xing",
  },
  {
    item1: "午",
    item2: "午",
    desc: "午午自刑",
    name: "刑",
    zhigan: "zhi",
    type: "xing",
    subtype: "5xing",
  },
  {
    item1: "酉",
    item2: "酉",
    desc: "酉酉自刑",
    name: "刑",
    zhigan: "zhi",
    type: "xing",
    subtype: "5xing",
  },
  {
    item1: "亥",
    item2: "亥",
    desc: "亥亥自刑",
    name: "刑",
    zhigan: "zhi",
    type: "xing",
    subtype: "5xing",
  },
];
const zhiXing3List = [
  {
    item1: "丑",
    item2: "未",
    item3: "戌",
    desc: "丑未戌三刑,恃势之刑",
    name: "三刑",
    zhigan: "zhi",
    type: "xing",
    bantype: "banxing",
    subtype: "3xing",
  },
  {
    item1: "寅",
    item2: "巳",
    item3: "申",
    desc: "寅巳申三刑,无恩之刑",
    name: "三刑",
    zhigan: "zhi",
    type: "xing",
    bantype: "banxing",
    subtype: "3xing",
  },
];

const zhiHui3List = [
  {
    item1: "寅",
    item2: "卯",
    item3: "辰",
    desc: "寅、卯、辰三会东方木",
    name: "三会",
    zhigan: "zhi",
    type: "hui",
    bantype: "banhui",
    subtype: "3hui",
    wuxing: "木",
  },
  {
    item1: "巳",
    item2: "午",
    item3: "未",
    desc: "巳、午、未三会南方火",
    name: "三会",
    zhigan: "zhi",
    type: "hui",
    bantype: "banhui",
    subtype: "3hui",
    wuxing: "火",
  },
  {
    item1: "申",
    item2: "酉",
    item3: "戌",
    desc: "申、酉、戌三会西方土",
    name: "三会",
    zhigan: "zhi",
    type: "hui",
    bantype: "banhui",
    subtype: "3hui",
    wuxing: "土",
  },
  {
    item1: "亥",
    item2: "子",
    item3: "丑",
    desc: "亥、子、丑三会北方水",
    name: "三会",
    zhigan: "zhi",
    type: "hui",
    bantype: "banhui",
    subtype: "3hui",
    wuxing: "水",
  },
];

// 地支害
const zhiHaiList = [
  {
    item1: "子",
    item2: "未",
    desc: "子未相害",
    name: "害",
    zhigan: "zhi",
    type: "hai",
    subtype: "6hai",
  },
  {
    item1: "丑",
    item2: "午",
    desc: "丑午相害",
    name: "害",
    zhigan: "zhi",
    type: "hai",
    subtype: "6hai",
  },
  {
    item1: "寅",
    item2: "巳",
    desc: "寅巳相害",
    name: "害",
    zhigan: "zhi",
    type: "hai",
    subtype: "6hai",
  },
  {
    item1: "卯",
    item2: "辰",
    desc: "卯辰相害",
    name: "害",
    zhigan: "zhi",
    type: "hai",
    subtype: "6hai",
  },
  {
    item1: "申",
    item2: "亥",
    desc: "申亥相害",
    name: "害",
    zhigan: "zhi",
    type: "hai",
    subtype: "6hai",
  },
  {
    item1: "酉",
    item2: "戌",
    desc: "酉戌相害",
    name: "害",
    zhigan: "zhi",
    type: "hai",
    subtype: "6hai",
  },
];

// 三元比较 是否符合条件
const hcxh3PanDuan = (
  item1,
  item2,
  item3,
  itemwei1,
  itemwei2,
  itemwei3,
  list,
  type
) => {
  let res = [];
  for (let index = 0; index < list.length; index++) {
    const element = list[index];
    if (
      (element.item1 == item1 &&
        element.item2 == item2 &&
        element.item3 == item3) ||
      (element.item1 == item1 &&
        element.item2 == item3 &&
        element.item3 == item2) ||
      (element.item1 == item2 &&
        element.item2 == item1 &&
        element.item3 == item3) ||
      (element.item1 == item2 &&
        element.item2 == item3 &&
        element.item3 == item1) ||
      (element.item1 == item3 &&
        element.item2 == item2 &&
        element.item3 == item1) ||
      (element.item1 == item3 &&
        element.item2 == item1 &&
        element.item3 == item2)
    )
      res.push({
        type: element.type,
        subtype: element.subtype,
        item1,
        item2,
        item3,
        itemwei1,
        itemwei2,
        itemwei3,
        desc: element.desc,
        name: element.name,
        zhigan: element.zhigan,
        wuxing: element.wuxing,
        display2:
          item1 +
          "、" +
          geTimeDesc(itemwei2) +
          item2 +
          "和" +
          geTimeDesc(itemwei3) +
          item3 +
          element.name +
          (element.wuxing || ""),
        display:
          geTimeDesc(itemwei1) +
          item1 +
          "、" +
          geTimeDesc(itemwei2) +
          item2 +
          "和" +
          geTimeDesc(itemwei3) +
          item3 +
          element.name +
          (element.wuxing || ""),
      });
  }
  return res;
};
// 二元比较 是否符合条件
const hcxhPanDuan = (item1, item2, itemwei1, itemwei2, list, type) => {
  let res = [];
  for (let index = 0; index < list.length; index++) {
    const element = list[index];
    // console.log("hcxhPanDuan", element);
    if (
      (element.item1 == item1 && element.item2 == item2) ||
      (element.item1 == item2 && element.item2 == item1)
    ) {
      if (element.item3) {
        res.push({
          type: element.bantype,
          subtype: element.subtype,
          item1,
          item2,
          itemwei1,
          itemwei2,
          desc: "(半)" + element.desc,
          name: element.name2 || element.name,
          zhigan: element.zhigan,
          wuxing: element.wuxing,
          display2:
            item1 +
            "和" +
            geTimeDesc(itemwei2) +
            item2 +
            (element.name2 || element.name) +
            (element.wuxing || ""),
          display:
            geTimeDesc(itemwei1) +
            item1 +
            "和" +
            geTimeDesc(itemwei2) +
            item2 +
            (element.name2 || element.name) +
            (element.wuxing || ""),
        });
      } else {
        res.push({
          type: element.type,
          subtype: element.subtype,
          item1,
          item2,
          itemwei1,
          itemwei2,
          desc: element.desc,
          name: element.name,
          zhigan: element.zhigan,
          wuxing: element.wuxing,
          display2:
            item1 +
            "和" +
            geTimeDesc(itemwei2) +
            item2 +
            element.name +
            (element.wuxing || ""),
          display:
            geTimeDesc(itemwei1) +
            item1 +
            "和" +
            geTimeDesc(itemwei2) +
            item2 +
            element.name +
            (element.wuxing || ""),
        });
      }
    }

    if (
      element.item3 &&
      ((element.item1 == item1 && element.item3 == item2) ||
        (element.item1 == item2 && element.item3 == item1))
    ) {
      res.push({
        type: element.bantype,
        subtype: element.subtype,
        item1,
        item2,
        itemwei1,
        itemwei2,
        desc: "(半)" + element.desc,
        name: element.name2 || element.name,
        zhigan: element.zhigan,
        wuxing: element.wuxing,
        display2:
          item1 +
          "和" +
          geTimeDesc(itemwei2) +
          item2 +
          (element.name2 || element.name) +
          (element.wuxing || ""),
        display:
          geTimeDesc(itemwei1) +
          item1 +
          "和" +
          geTimeDesc(itemwei2) +
          item2 +
          (element.name2 || element.name) +
          (element.wuxing || ""),
      });
    }
    if (
      (element.item3 && element.item2 == item1 && element.item3 == item2) ||
      (element.item2 == item2 && element.item3 == item1)
    ) {
      res.push({
        type: element.bantype,
        subtype: element.subtype,
        item1,
        item2,
        itemwei1,
        itemwei2,
        desc: "(半)" + element.desc,
        name: element.name2 || element.name,
        zhigan: element.zhigan,
        wuxing: element.wuxing,
        display2:
          item1 +
          "和" +
          geTimeDesc(itemwei2) +
          item2 +
          (element.name2 || element.name) +
          (element.wuxing || ""),
        display:
          geTimeDesc(itemwei1) +
          item1 +
          "和" +
          geTimeDesc(itemwei2) +
          item2 +
          (element.name2 || element.name) +
          (element.wuxing || ""),
      });
    }
  }
  return res;
};
// 比较2元素，元素描述，比较类型 ，获得比较元素的天干地支，自动判断合冲刑害  startnum 主要判断大运流年流月
const PanDuanOnetype = (itemsArr, itemsDescArr, typelist, startnum) => {
  const itemsarr = [];
  for (let index = 0; index < (startnum || itemsArr.length); index++) {
    const element1 = itemsArr[index];
    for (let index2 = index + 1; index2 < itemsArr.length; index2++) {
      const element2 = itemsArr[index2];
      for (let typeindex = 0; typeindex < typelist.length; typeindex++) {
        const typearr = typelist[typeindex].list;
        const newdesc = hcxhPanDuan(
          element1,
          element2,
          itemsDescArr[index],
          itemsDescArr[index2],
          typearr,
          typelist[typeindex].type
        );
        itemsarr.push(...newdesc);
      }
    }
  }
  return itemsarr;
};

// 比较3元素，元素描述，比较类型 ，获得比较元素的天干地支，自动判断合冲刑害 startnum 主要判断大运流年流月
const PanDuan3Onetype = (itemsArr, itemsDescArr, typelist, startnum) => {
  const resitemsarr = [];
  for (let typeindex = 0; typeindex < typelist.length; typeindex++) {
    const typearr = typelist[typeindex].list;

    for (let index = 0; index < (startnum || itemsArr.length); index++) {
      const element1 = itemsArr[index];
      for (let index2 = index + 1; index2 < itemsArr.length; index2++) {
        const element2 = itemsArr[index2];

        const newdesc2 = hcxhPanDuan(
          element1,
          element2,
          itemsDescArr[index],
          itemsDescArr[index2],
          typearr,
          typelist[typeindex].type
        );

        let biaozhi2 = false;
        for (let index3 = index2 + 1; index3 < itemsArr.length; index3++) {
          const element3 = itemsArr[index3];

          const newdesc3 = hcxh3PanDuan(
            element1,
            element2,
            element3,
            itemsDescArr[index],
            itemsDescArr[index2],
            itemsDescArr[index3],
            typearr,
            typelist[typeindex].type
          );
          resitemsarr.push(...newdesc3);

          if (newdesc3.length > 0) {
            biaozhi2 = true;
          }
        }
        // 只有半三合
        if (!biaozhi2 && typelist[typeindex].type == "zhihe3") {
          resitemsarr.push(...newdesc2);
        }
      }
    }
  }
  return resitemsarr;
};

const typeganlist = [
  { type: "ganhe", list: GanHeList },
  { type: "ganchong", list: GanChongList },
];
const typezhilist = [
  { type: "zhihe6", list: ZhiHe6List },
  { type: "zhichong", list: zhiChongList },
  { type: "zhixing", list: zhiXingList },
  { type: "zhihai", list: zhiHaiList },
];
const typezhilist3 = [
  { type: "zhihe3", list: ZhiHe3List },
  { type: "zhixing3", list: zhiXing3List },
  { type: "zhihui3", list: zhiHui3List },
];
const timeDescList = {
  year: "年",
  month: "月",
  day: "日",
  time: "时",
  dayun: "大运",
  liunian: "流年",
  liuyue: "流月",
  blank: "",
};
const geTimeDesc = (timetype) => {
  return timeDescList[timetype];
};

const getHeCXingHaidesc = (zhigan, timetype) => {
  const zhiganDescList = { gan: "干", zhi: "支", blank: "", blank: "" };
  return timeDescList[timetype] + zhiganDescList[zhigan];
};
// 判断命局中的刑冲合害
export const mingjuPanDuan = (data) => {
  // 四柱
  const ganlist = PanDuanOnetype(
    [data.year.gan, data.month.gan, data.day.gan, data.time.gan],
    //["年干", "月干", "日干", "时干"],
    ["year", "month", "day", "time"],
    typeganlist
  );

  const zhilist1 = PanDuanOnetype(
    [data.year.zhi, data.month.zhi, data.day.zhi, data.time.zhi],
    //["年支", "月支", "日支", "时支"],
    ["year", "month", "day", "time"],
    typezhilist
  );

  const zhilist3 = PanDuan3Onetype(
    [data.year.zhi, data.month.zhi, data.day.zhi, data.time.zhi],
    //["年支", "月支", "日支", "时支"],
    ["year", "month", "day", "time"],
    typezhilist3
  );
  console.log("ganlist", ganlist);
  console.log("zhilist1", zhilist1);
  console.log("zhilist3", zhilist3);
  ganlist.push(...zhilist1, ...zhilist3);

  return ganlist;
};
//获得大运流年的刑冲合害
export const liunianPanDuan = (data, liuniandata) => {
  //大运流年和命局比较，不包含命局内部比较
  // const ganlist=[];

  const ganlist = PanDuanOnetype(
    [
      liuniandata.gan,
      data.year.gan,
      data.month.gan,
      data.day.gan,
      data.time.gan,
    ],
    // ["流年", "年干", "月干", "日干", "时干"],
    ["liunian", "year", "month", "day", "time"],
    typeganlist,
    1
  );

  const zhilist1 = PanDuanOnetype(
    [
      liuniandata.zhi,
      data.year.zhi,
      data.month.zhi,
      data.day.zhi,
      data.time.zhi,
    ],
    //["流年支", "大运支","年支", "月支", "日支", "时支"],
    ["liunian", "year", "month", "day", "time"],
    typezhilist,
    1
  );
  const zhilist3 = PanDuan3Onetype(
    [
      liuniandata.zhi,
      data.year.zhi,
      data.month.zhi,
      data.day.zhi,
      data.time.zhi,
    ],
    //["流年支", "大运支","年支", "月支", "日支", "时支"],
    ["liunian", "year", "month", "day", "time"],
    typezhilist3,
    1
  );
  ganlist.push(...zhilist1, ...zhilist3);
  return ganlist;
};
//获得大运流年的刑冲合害
export const dayunliunianPanDuan = (data, dayundata, liuniandata) => {
  //大运流年和命局比较，不包含命局内部比较
  const ganlist = PanDuanOnetype(
    [
      liuniandata.gan,
      dayundata.gan,
      data.year.gan,
      data.month.gan,
      data.day.gan,
      data.time.gan,
    ],
    // ["流年", "大运干","年干", "月干", "日干", "时干"],
    ["liunian", "dayun", "year", "month", "day", "time"],
    typeganlist,
    2
  );
  const zhilist1 = PanDuanOnetype(
    [
      liuniandata.zhi,
      dayundata.zhi,
      data.month.zhi,
      data.day.zhi,
      data.time.zhi,
    ],
    //["流年支", "大运支","年支", "月支", "日支", "时支"],
    ["liunian", "dayun", "year", "month", "day", "time"],
    typezhilist,
    2
  );
  const zhilist3 = PanDuan3Onetype(
    [
      liuniandata.zhi,
      dayundata.zhi,
      data.month.zhi,
      data.day.zhi,
      data.time.zhi,
    ],
    //["流年支", "大运支","年支", "月支", "日支", "时支"],
    ["liunian", "dayun", "year", "month", "day", "time"],
    typezhilist3,
    2
  );
  ganlist.push(...zhilist1, ...zhilist3);
  return ganlist;
};
// 得到大运的合冲刑害
export const dayunPanDuan = (data, dayundata) => {
  //大运流年和命局比较，不包含命局内部比较
  const ganlist = PanDuanOnetype(
    [dayundata.gan, data.year.gan, data.month.gan, data.day.gan, data.time.gan],
    // ["流年", "大运干","年干", "月干", "日干", "时干"],
    ["dayun", "year", "month", "day", "time"],
    typeganlist,
    1
  );
  const zhilist1 = PanDuanOnetype(
    [dayundata.zhi, data.year.gan, data.month.zhi, data.day.zhi, data.time.zhi],
    //[ "大运支","年支", "月支", "日支", "时支"],
    ["dayun", "year", "month", "day", "time"],
    typezhilist,
    1
  );
  const zhilist3 = PanDuan3Onetype(
    [dayundata.zhi, data.year.zhi, data.month.zhi, data.day.zhi, data.time.zhi],
    //["大运支","年支", "月支", "日支", "时支"],
    ["dayun", "year", "month", "day", "time"],
    typezhilist3,
    1
  );
  ganlist.push(...zhilist1, ...zhilist3);
  return ganlist;
};

export const XiJiColorList = {
  喜: "#006400",
  忌: "#EE0000",
};

export const WuXingColorList = {
  金: "#FFA500",
  木: "#006400",
  水: "#00BFFF",
  火: "#EE0000",
  土: "#8B4513",
};

// 填充填充命局和大运流年合冲刑害
export const FillHeCXingHai = (data, dayun) => {
  // 填充大运流年的合冲刑害数据
  for (let index = 0; index < dayun.length; index++) {
    const dayundata = dayun[index];
    let hecxinghai = [];
    if (index == 0) {
      dayundata.hecxinghai = hecxinghai;
    } else {
      hecxinghai = dayunPanDuan(data, dayundata);
      dayundata.hecxinghai = hecxinghai;
      dayundata.ganhcxinghai = hecxinghai.filter(
        (item) => item.zhigan == "gan"
      );
      dayundata.zhihcxinghai = hecxinghai.filter(
        (item) => item.zhigan == "zhi"
      );
    }

    const liunian = dayun[index].liunian;
    for (let lindex = 0; lindex < liunian.length; lindex++) {
      const liuniandata = liunian[lindex];
      /*
      if (lindex == 0 || index == 0) {
        liuniandata.hecxinghai = hecxinghai;
        continue;
      }
      */

      const lhecxinghai = liunianPanDuan(data, liuniandata);
      //const lhecxinghai = dayunliunianPanDuan(data, dayundata, liuniandata);
      liuniandata.hecxinghai = lhecxinghai;
      liuniandata.ganhcxinghai = lhecxinghai.filter(
        (item) => item.zhigan == "gan"
      );
      liuniandata.zhihcxinghai = lhecxinghai.filter(
        (item) => item.zhigan == "zhi"
      );
    }
  }
  // 填充命局盘合冲刑害数据
  const hcxinghaiArr = mingjuPanDuan(data);
  const clearlist = ["year", "month", "day", "time"];
  for (let i = 0; i < clearlist.length; i++) {
    data[clearlist[i]].ganhcxinghai = [];
    data[clearlist[i]].zhihcxinghai = [];

    data[clearlist[i]].dayunganhcxinghai = [];
    data[clearlist[i]].dayunzhihcxinghai = [];

    data[clearlist[i]].liunianganhcxinghai = [];
    data[clearlist[i]].liunianzhihcxinghai = [];
  }

  for (let index = 0; index < hcxinghaiArr.length; index++) {
    const item = hcxinghaiArr[index];
    const field = item.zhigan + "hcxinghai";
    if (
      item.itemwei1 == "year" ||
      item.itemwei2 == "year" ||
      item.itemwei3 == "year"
    ) {
      data.year[field].push(item);
    }
    if (
      item.itemwei1 == "month" ||
      item.itemwei2 == "month" ||
      item.itemwei3 == "month"
    ) {
      data.month[field].push(item);
    }
    if (
      item.itemwei1 == "day" ||
      item.itemwei2 == "day" ||
      item.itemwei3 == "day"
    ) {
      data.day[field].push(item);
    }
    if (
      item.itemwei1 == "time" ||
      item.itemwei2 == "time" ||
      item.itemwei3 == "time"
    ) {
      data.time[field].push(item);
    }
  }
};

// 输出四柱和大运基本数据
export const getBaseData = (bz, dayun) => {
  const data = {};

  data.year = {
    gan: bz.getYearGan(), // 干支
    ganShiSheng: bz.getYearShiShenGan(), // 干十神
    ganWuXing: bz.getYearWuXing()[0], // 干五行
    zhi: bz.getYearZhi(), // 支
    zhiWuXing: bz.getYearWuXing()[1], // 干五行
    zhiGan: bz.getYearHideGan()[0], // 支干
    zhiShiSheng: bz.getYearShiShenZhi()[0], // 支干十神
    zhiCangGan: bz.getYearHideGan().slice(1), // 支藏干 不包括第1个
    zhiCangGanShiSheng: bz.getYearShiShenZhi().slice(1), // 支藏干十神 不包括第1个
    zhiCangGanWuXing: bz
      .getYearHideGan()
      .slice(1)
      .map((item) => bz.getWuXing(item)),
    xunKong: bz.getYearXunKong(),
    naYin: bz.getYearNaYin(),
    diShi: bz.getYearDiShi(),
  };

  data.month = {
    gan: bz.getMonthGan(), // 干支
    ganShiSheng: bz.getMonthShiShenGan(), // 干十神
    ganWuXing: bz.getMonthWuXing()[0], // 干五行
    zhi: bz.getMonthZhi(), // 支
    zhiWuXing: bz.getMonthWuXing()[1], // 干五行
    zhiGan: bz.getMonthHideGan()[0], // 支干
    zhiShiSheng: bz.getMonthShiShenZhi()[0], // 支干十神
    zhiCangGan: bz.getMonthHideGan().slice(1), // 支藏干 不包括第1个
    zhiCangGanShiSheng: bz.getMonthShiShenZhi().slice(1), // 支藏干十神 不包括第1个
    zhiCangGanWuXing: bz
      .getMonthHideGan()
      .slice(1)
      .map((item) => bz.getWuXing(item)),
    xunKong: bz.getMonthXunKong(),
    naYin: bz.getMonthNaYin(),
    diShi: bz.getMonthDiShi(),
  };

  data.day = {
    gan: bz.getDayGan(), // 干支
    ganShiSheng: bz.getDayShiShenGan(), // 干十神
    ganWuXing: bz.getDayWuXing()[0], // 干五行
    zhi: bz.getDayZhi(), // 支
    zhiWuXing: bz.getDayWuXing()[1], // 干五行
    zhiGan: bz.getDayHideGan()[0], // 支干
    zhiShiSheng: bz.getDayShiShenZhi()[0], // 支干十神
    zhiCangGan: bz.getDayHideGan().slice(1), // 支藏干 不包括第1个
    zhiCangGanShiSheng: bz.getDayShiShenZhi().slice(1), // 支藏干十神 不包括第1个
    zhiCangGanWuXing: bz
      .getDayHideGan()
      .slice(1)
      .map((item) => bz.getWuXing(item)),
    xunKong: bz.getDayXunKong(),
    naYin: bz.getDayNaYin(),
    diShi: bz.getDayDiShi(),
  };

  data.time = {
    gan: bz.getTimeGan(), // 干支
    ganShiSheng: bz.getTimeShiShenGan(), // 干十神
    ganWuXing: bz.getTimeWuXing()[0], // 干五行
    zhi: bz.getTimeZhi(), // 支
    zhiWuXing: bz.getTimeWuXing()[1], // 干五行
    zhiGan: bz.getTimeHideGan()[0], // 支干
    zhiShiSheng: bz.getTimeShiShenZhi()[0], // 支干十神

    zhiCangGan: bz.getTimeHideGan().slice(1), // 支藏干 不包括第1个
    zhiCangGanShiSheng: bz.getTimeShiShenZhi().slice(1), // 支藏干十神 不包括第1个
    zhiCangGanWuXing: bz
      .getTimeHideGan()
      .slice(1)
      .map((item) => bz.getWuXing(item)),
    xunKong: bz.getTimeXunKong(),
    naYin: bz.getTimeNaYin(),
    diShi: bz.getTimeDiShi(),
  };
  //setSiJuData(data);

  // LunarYear.fromYear(ny)

  const yundata = [];

  for (let index = 0; index < dayun.length; index++) {
    const yunitem = dayun[index];
    console.log(yunitem.getGanZhi());
    const gan = yunitem.getGanZhi()[0];
    const zhi = yunitem.getGanZhi()[1];

    const item = {
      year: yunitem.getStartYear(),
      age: yunitem.getStartAge(),
      ganzhi: yunitem.getGanZhi(),
      gan,
      zhi,
      xunKong: yunitem.getXunKong(),
      liunian: [],
    };
    const liunian = yunitem.getLiuNian();

    item.ganShiSheng = bz.getShiShenGan(item.gan || "");
    item.zhiShiSheng = bz.getShiShenZhi(item.zhi || "")[0];
    item.ganWuXing = bz.getWuXing(item.gan);
    // console.log("item.zhi",item.zhi)
    item.zhiGan = item.zhi ? bz.getHideGan(item.zhi)[0] : "";
    item.zhiCangGan = item.zhi ? bz.getHideGan(item.zhi).slice(1) : [];
    item.zhiWuXing = bz.getWuXing(item.zhiGan);
    item.zhiCangGanWuXing = item.zhiCangGan.map((item) => bz.getWuXing(item));
    item.zhiCangGanShiSheng = item.zhiCangGan.map((item) =>
      bz.getShiShenGan(item)
    );
    item.diShi = getZhangSheng(item.gan, item.zhi);

    for (let lindex = 0; lindex < liunian.length; lindex++) {
      const liuitem = {
        year: liunian[lindex].getYear(),
        age: liunian[lindex].getAge(),
        ganzhi: liunian[lindex].getGanZhi(),
        gan: liunian[lindex].getGanZhi()[0],
        zhi: liunian[lindex].getGanZhi()[1],
        xunKong: yunitem.getXunKong(),
      };

      liuitem.ganShiSheng = bz.getShiShenGan(liuitem.ganzhi[0] || "");
      liuitem.zhiShiSheng = bz.getShiShenZhi(liuitem.ganzhi[1] || "")[0];
      liuitem.ganWuXing = bz.getWuXing(liuitem.gan);

      liuitem.zhiGan = bz.getHideGan(liuitem.zhi)[0];
      liuitem.zhiCangGan = bz.getHideGan(liuitem.zhi).slice(1);
      liuitem.zhiWuXing = bz.getWuXing(liuitem.zhiGan);
      liuitem.zhiCangGanWuXing = liuitem.zhiCangGan.map((item2) =>
        bz.getWuXing(item2)
      );
      liuitem.zhiCangGanShiSheng = liuitem.zhiCangGan.map((item2) =>
        bz.getShiShenGan(item2)
      );
      liuitem.diShi = getZhangSheng(liuitem.gan, liuitem.zhi);
      item.liunian.push(liuitem);
    }
    yundata.push(item);
  }
  console.log("gandata yundata:", yundata);

  return { sijudata: data, yundata };
};
const setCurYunAndLiunian = (data) => {
  const now = new Date();
  const curYear = now.getFullYear();
  let curyun = null;
  for (let index = 0; index < data.yundata.length; index++) {
    const liunian = data.yundata[index].liunian;
    for (let lindex = 0; lindex < liunian.length; lindex++) {
      if (curYear == liunian[lindex].year && index != 0) {
        curyun = getCurYunAndLiunianItem(data.yundata[index], liunian[lindex]);
        curyun.dayunindex = index;
        curyun.liunianindex = lindex;
      }
    }
  }
  if (!curyun) {
    curyun = getCurYunAndLiunianItem(
      data.yundata[1],
      data.yundata[1].liunian[0]
    );
    curyun.dayunindex = 1;
    curyun.liunianindex = 0;
  }
  data.curyun = curyun;
};
// 获取当前大运和流年数据排盘
export const getCurYunAndLiunianItem = (yunitem, liunianitem) => {
  const curyun = {};
  curyun.dayun = {
    year: yunitem.year,
    age: yunitem.age,
    ganXiji: yunitem.ganXiji,
    zhiXiji: yunitem.zhiXiji,
    ganhcxinghai: yunitem.ganhcxinghai, //刑冲破害 ganhcxingha
    zhihcxinghai: yunitem.zhihcxinghai, //刑冲破害
    gan: yunitem.gan, // 干支
    ganShiSheng: yunitem.ganShiSheng, // 干十神
    ganWuXing: yunitem.ganWuXing, // 干五行
    zhi: yunitem.zhi, // 支
    zhiWuXing: yunitem.zhiWuXing, // 干五行
    zhiGan: yunitem.zhiGan, // 支干 支藏干 第1个
    zhiShiSheng: yunitem.zhiShiSheng, // 支干十神 支藏干 第1个

    zhiCangGan: yunitem.zhiCangGan, // 支藏干 不包括第1个
    zhiCangGanShiSheng: yunitem.zhiCangGanShiSheng, // 支藏干十神 不包括第1个
    zhiCangGanWuXing: yunitem.zhiCangGanWuXing, // 支藏干五行不包括第1个
    xunKong: yunitem.xunKong, // 旬空
    naYin: yunitem.naYin, // 纳音
    diShi: yunitem.diShi, // 长生
  };

  curyun.liunian = {
    year: liunianitem.year,
    age: liunianitem.age,
    ganXiji: liunianitem.ganXiji,
    zhiXiji: liunianitem.zhiXiji,
    ganhcxinghai: liunianitem.ganhcxinghai, //刑冲破害
    zhihcxinghai: liunianitem.zhihcxinghai, //刑冲破害
    gan: liunianitem.gan, // 干支
    ganShiSheng: liunianitem.ganShiSheng, // 干十神
    ganWuXing: liunianitem.ganWuXing, // 干五行
    zhi: liunianitem.zhi, // 支
    zhiWuXing: liunianitem.zhiWuXing, // 干五行
    zhiGan: liunianitem.zhiGan, // 支干 支藏干 第1个
    zhiShiSheng: liunianitem.zhiShiSheng, // 支干十神 支藏干 第1个

    zhiCangGan: liunianitem.zhiCangGan, // 支藏干 不包括第1个
    zhiCangGanShiSheng: liunianitem.zhiCangGanShiSheng, // 支藏干十神 不包括第1个
    zhiCangGanWuXing: liunianitem.zhiCangGanWuXing, // 支藏干五行不包括第1个
    xunKong: liunianitem.xunKong, // 旬空
    naYin: liunianitem.naYin, // 纳音
    diShi: liunianitem.diShi, // 长生
  };
  return curyun;
};

//通过四柱天干地支，找最近对应的年份
export const sijuToTime = (siju) => {
  const nian = siju[0];
  const month = siju[1];
  const day = siju[2];
  const time = siju[3];
  const res={ok:0, solar: null,err:'天干地支出错，没有找到对应四柱时间'}

  for (let index = 0; index < siju.length; index++) {
    const element = siju[index];
    const gzindex = gzList.findIndex((item) => item == element);
    if (gzindex == -1) res.err="四柱"+element+"输入有错";
  }

  const year = 1984; // 1984 参考年 1933
  let yeardiff = gzList.findIndex((item) => item == nian);
  const newyear = year + yeardiff;
  const years = [];
  // console.log("1-newyear", newyear);
// 推算大于 当前-1年时间，往前60年
if (newyear - new Date().getFullYear > -1) {
  yeardiff = yeardiff - 60;
}

  // 获取最近240年，4个年份
  for (let i = 0; i < 4; i++) {
    years.push(year - i * 60 + yeardiff);
  }

  //获得年开始月干,位置序号
  const monthganstartindex = ganList.findIndex(
    (item) => item == monthGanList[nian[0]]
  );

  //获得当前月干,位置序号
  const monthganindex = ganList.findIndex((item) => item == month[0]);
  // console.log("开始，当前", monthganstartindex, monthganindex);
  let difganmonth = monthganindex - monthganstartindex;
  if (difganmonth < 0) difganmonth = difganmonth + 10;

  const monthzhiindex = zhiList.findIndex((item) => item == month[1]);
  let difzhimonth = monthzhiindex - 2;
  if (difzhimonth < 0) difzhimonth = difzhimonth + 12;
  // 根据口诀 判断是否错误
  if (difzhimonth != difganmonth) {
    // res.err="月份天干地支错误";
  }

  //console.log("月份必须相同：", difzhimonth, difganmonth);
  // console.log("开始月份：", difganmonth + 2);

  // 年查找
  for (let i = 0; i < 4; i++) {
    const curyear = years[i];
    var d = Lunar.fromYmdHms(curyear, difganmonth + 1, 1, 1, 0, 0); //每月1号1点开始
    if (d.getYearInGanZhiExact() != nian) continue;
    //月
    for (let j = 0; j < 3; j++) {
      const monthday = d.nextMonth(j);

      // 日
      for (let k = 0; k < 60; k++) {
        const dayday = monthday.next(k);


        for (let h = 0; h < 12; h++) {
          const hourday = dayday.nextHour(h * 2);
          if (
            hourday.getTimeInGanZhi() == time &&
            hourday.getDayInGanZhiExact() == day &&
            hourday.getMonthInGanZhiExact() == month
          ) {
            // console.log(hourday.toString());
            res.solar=hourday.getSolar();
            res.time={
              year: res.solar.getYear(), // 1993
              month: res.solar.getMonth(), // 7
              day: res.solar.getDay(), // 20
              hour: res.solar.getHour(), // 22
              min: 0,
              sec: 0,
            }
            res.err='';
            res.ok=1;
            return res;
          }
        }
        // 时间推算出来    getTimeInGanZhiExact()
      }
    }
  }
  return res;
}

const qianList = ["正印", "偏印", "比肩", "劫财"]; // "正官", "七杀", "正财", "偏财"
// 判断身强身弱
export const getShenQiangRuoData = (sijudata) => {
  let core = 0;
  if (qianList.includes(sijudata.month.ganShiSheng)) {
    core = core + 12;
  }
  if (qianList.includes(sijudata.month.zhiShiSheng)) {
    core = core + 40;
  }
  if (qianList.includes(sijudata.day.zhiShiSheng)) {
    core = core + 12;
  }
  if (qianList.includes(sijudata.year.ganShiSheng)) {
    core = core + 4;
  }
  if (qianList.includes(sijudata.year.zhiShiSheng)) {
    core = core + 12;
  }
  if (qianList.includes(sijudata.time.ganShiSheng)) {
    core = core + 12;
  }
  if (qianList.includes(sijudata.time.zhiShiSheng)) {
    core = core + 12;
  }
  if (core > 50) return { qiang: true, core, title: "身强" }; //
  return { qiang: false, core, title: "身弱" };
};
// 基本判断婚姻
export const getHunYing = (sijudata) => {};

// 是否通根
export const setTongGen = (sijudata) => {
  const sijulist = ["year", "month", "day", "time"];
  for (let i = 0; i < sijulist.length; i++) {
    const ganitem = sijudata[sijulist[i]];
    ganitem.ganYouGen = { gen: 0, title: "" };

    for (let j = 0; j < sijulist.length; j++) {
      const zhiitem = sijudata[sijulist[j]];
      const zhigans = [zhiitem.zhiGan, ...zhiitem.zhiCangGan];
      const zhiganwuxings = [zhiitem.zhiWuXing, ...zhiitem.zhiCangGanWuXing];
      if (zhigans.includes(ganitem.gan)) {
        ganitem.ganYouGen = { gen: 2, title: "有根", title2: "有正根" };
      } else {
        console.log(
          "zhiganwuxings",
          zhiganwuxings,
          "ganitem.ganWuXing",
          ganitem.ganWuXing
        );
        if (zhiganwuxings.includes(ganitem.ganWuXing)) {
          if (ganitem.ganYouGen && ganitem.ganYouGen.gen == 0)
            ganitem.ganYouGen = { gen: 1, title: "有根", title2: "有偏根" };
        }
      }
    }
  }
};

//判断坐禄
export const setZuoLu = (sijudata) => {
  const sijulist = ["year", "month", "day", "time"];
  for (let i = 0; i < sijulist.length; i++) {
    const ganitem = sijudata[sijulist[i]];
    ganitem.ganZuoLu = { num: 0, title: "" };
    if (LuList[ganitem.gan] == ganitem.zhi) {
      // ganitem.ganZuoLu = { num: 1, title: "坐禄" };
      ganitem.ganZuoLu.num = ganitem.ganZuoLu.num + 1;
      ganitem.ganZuoLu.title = "坐禄";
      ganitem.ganZuoLu.ju.push(sijulist[i]);
    }
  }
};

//判断得禄
export const setDeLu = (sijudata) => {
  const sijulist = ["year", "month", "day", "time"];
  for (let i = 0; i < sijulist.length; i++) {
    const ganitem = sijudata[sijulist[i]];
    ganitem.ganDeLu = { num: 0, title: "",ju:[] };
    for (let j = 0; j < sijulist.length; j++) {
      const zhiitem = sijudata[sijulist[j]];
      if (LuList[ganitem.gan] == zhiitem.zhi && i != j) {
        ganitem.ganDeLu.num = ganitem.ganDeLu.num + 1;
        ganitem.ganDeLu.title = "得禄";
        ganitem.ganDeLu.ju.push(sijulist[j]);
      }
    }
  }
};

// 判断自带桃花； 如果判断yun=true：判断大运桃花
export const setTaoHua = (sijudata, yun) => {
  const sijulist = ["year", "day"];
  let taohuasijulist = ["month", "time"];
  if (yun) {
    taohuasijulist = ["dayun", "liunian", "month", "time"];
  }
  for (let i = 0; i < sijulist.length; i++) {
    const zhiItem = sijudata[sijulist[i]];
    zhiItem.taoHua = { num: 0, title: "", ju: [] };
    for (let j = 0; j < taohuasijulist.length; j++) {
      const taohuaZhiitem = sijudata[taohuasijulist[j]];
      if (TaoHuaList[zhiItem.zhi] == taohuaZhiitem.zhi) {
        zhiItem.taoHua.num = zhiItem.taoHua.num + 1;
        zhiItem.taoHua.title = "桃花";
        zhiItem.taoHua.ju.push(taohuasijulist[j]);
        break;
      }
    }
  }
};

// 判断羊刃
// 判断羊刃； 如果判断yun=true：判断羊刃
export const setYanRen = (sijudata, yun) => {
  let sijulist = ["year", "month", "day", "time"];

  if (yun) {
    sijulist = ["dayun", "liunian", "year", "month", "day", "time"];
  }
  const gan = sijudata.day.gan;
  for (let i = 0; i < sijulist.length; i++) {
    const zhiitem = sijudata[sijulist[i]];
    sijudata.day.yanRen = { num: 0, title: "", ju: [] };
    if (YanRenList[gan] == zhiitem.zhi) {
      sijudata.day.yanRen.num = sijudata.day.yanRen.num + 1;
      sijudata.day.yanRen.title = "羊刃";
      sijudata.day.yanRen.ju.push(sijulist[i]);
      break;
    }
  }
};

// 用神喜忌// 返回是否喜用，还是忌用
export const getYongXingXiJi = (sijudata, dayun, shengqiang) => {
  const sijulist = ["year", "month", "day", "time"];
  for (let i = 0; i < sijulist.length; i++) {
    sijudata[sijulist[i]].ganXiji = getYongXingXiJiItem(
      sijudata[sijulist[i]].ganShiSheng,
      shengqiang
    );
    sijudata[sijulist[i]].zhiXiji = getYongXingXiJiItem(
      sijudata[sijulist[i]].zhiShiSheng,
      shengqiang
    );
  }
  for (let index = 0; index < dayun.length; index++) {
    const dayunitem = dayun[index];
    dayunitem.ganXiji = getYongXingXiJiItem(dayunitem.ganShiSheng, shengqiang);
    dayunitem.zhiXiji = getYongXingXiJiItem(dayunitem.zhiShiSheng, shengqiang);
    const liunian = dayunitem.liunian || [];
    for (let lindex = 0; lindex < liunian.length; lindex++) {
      const liunianitem = liunian[lindex];
      liunianitem.ganXiji = getYongXingXiJiItem(
        liunianitem.ganShiSheng,
        shengqiang
      );
      liunianitem.zhiXiji = getYongXingXiJiItem(
        liunianitem.zhiShiSheng,
        shengqiang
      );
    }
  }
};
export const getYongXingXiJiItem = (shisheng, shengqiang) => {
  // console.log(shengqiang)
  if (qianList.includes(shisheng)) {
    return shengqiang
      ? { xiji: false, title: "忌" }
      : { xiji: true, title: "喜" };
  } else {
    return shengqiang
      ? { xiji: true, title: "喜" }
      : { xiji: false, title: "忌" };
  }
};

// 判断财富
export const getbzObj = (selectType, time, xingbie) => {
  let tmplunar;
  if (selectType == 1) {
    // 阳历
    tmplunar = Solar.fromYmdHms(
      time.year,
      time.month,
      time.day,
      time.hour,
      time.min,
      time.sec
    ).getLunar();
  } else {
    tmplunar = Lunar.fromYmdHms(
      time.year,
      time.month,
      time.day,
      time.hour,
      time.min,
      time.sec
    );
  }

  const bz = tmplunar.getEightChar();
  const dayun = tmplunar
    .getEightChar()
    .getYun(xingbie == 1 ? 1 : 0)
    .getDaYun();
  return { bz, dayun };
};

export const getAllData = (selectType, time, xingbie) => {
  const { bz, dayun } = getbzObj(selectType, time, xingbie);
  const data = getBaseData(bz, dayun);

  // 刑冲合害
  FillHeCXingHai(data.sijudata, data.yundata);
  // 身强身弱
  data.info = { xingbie };
  data.info.shenqaingruo = getShenQiangRuoData(data.sijudata);

  // 配置当前大运流年
  setCurYunAndLiunian(data);
  // 判断是否有根
  setTongGen(data.sijudata);
  // 判断是否坐禄或得禄
  setZuoLu(data.sijudata);
  setDeLu(data.sijudata);

  // 判断格局  TODO
  // 用神喜忌
  getYongXingXiJi(data.sijudata, data.yundata, data.info.shenqaingruo.qiang);

  return data;
};
