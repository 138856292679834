import React, { useState, useMemo } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import {
  getAllData,
  WuXingColorList,
  ShiShengJCList,
  XiJiColorList,
  getCurYunAndLiunianItem,
  sijuToTime
} from "./utils/baziutils";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/joy/IconButton";
import Alert from '@mui/joy/Alert';
import {
  Table,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Grid,
  Tooltip,
  Sheet,
  Select,
  Option,
  Box,
} from "@mui/joy";
import InputTime, { SelectRadio,InputSiZhu, AutoCurTime } from "./InputTime";
import area from "./constants/area.json";
import suncalc from "suncalc";
function Bazi() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [areaList2, setAreaList2] = useState(area[0].subitems);
  const [areaList3, setAreaList3] = useState(areaList2[0].subitems);
  const [langlat, setLanglat] = useState({ lng: "116.41005", lat: "39.93157" });
  
  const [sizhu, setSizhu] = useState(['','','','']);
  const [error, setError] = useState('');
  
  // 四柱
  const [siJuData, setSiJuData] = useState({
    year: {
      gan: "", // 干支
      ganShiSheng: "", // 干十神
      ganWuXing: "", // 干五行
      zhi: "", // 支
      zhiWuXing: "", // 干五行
      zhiGan: "", // 支干
      zhiShiSheng: "", // 支干十神
      zhiCangGan: [], // 支藏干 不包括第1个
      zhiCangGanShiSheng: [], // 支藏干十神 不包括第1个
    },
    month: {
      gan: "", // 干支
      ganShiSheng: "", // 干十神
      ganWuXing: "", // 干五行
      zhi: "", // 支
      zhiWuXing: "", // 干五行
      zhiGan: "", // 支干
      zhiShiSheng: "", // 支干十神
      zhiCangGan: [], // 支藏干 不包括第1个
      zhiCangGanShiSheng: [], // 支藏干十神 不包括第1个
    },
    day: {
      gan: "", // 干支
      ganShiSheng: "", // 干十神
      ganWuXing: "", // 干五行
      zhi: "", // 支
      zhiWuXing: "", // 干五行
      zhiGan: "", // 支干
      zhiShiSheng: "", // 支干十神
      zhiCangGan: [], // 支藏干 不包括第1个
      zhiCangGanShiSheng: [], // 支藏干十神 不包括第1个
    },
    time: {
      gan: "", // 干支
      ganShiSheng: "", // 干十神
      ganWuXing: "", // 干五行
      zhi: "", // 支
      zhiWuXing: "", // 干五行
      zhiGan: "", // 支干
      zhiShiSheng: "", // 支干十神
      zhiCangGan: [], // 支藏干 不包括第1个
      zhiCangGanShiSheng: [], // 支藏干十神 不包括第1个
    },
  });
  const [dayunlist, setDaYunList] = useState([]);
  const [info, setInfo] = useState({});
  const [curYunIndex, setCurYunIndex] = useState({
    dayunindex: 1,
    liunianindex: 0,
  });
  const [siJuYunData, setSiJuYunData] = useState({
    liunian: {
      gan: "", // 干支
      ganShiSheng: "", // 干十神
      ganWuXing: "", // 干五行
      zhi: "", // 支
      zhiWuXing: "", // 干五行
      zhiGan: "", // 支干
      zhiShiSheng: "", // 支干十神
      zhiCangGan: [], // 支藏干 不包括第1个
      zhiCangGanShiSheng: [], // 支藏干十神 不包括第1个
    },
    dayun: {
      gan: "", // 干支
      ganShiSheng: "", // 干十神
      ganWuXing: "", // 干五行
      zhi: "", // 支
      zhiWuXing: "", // 干五行
      zhiGan: "", // 支干
      zhiShiSheng: "", // 支干十神
      zhiCangGan: [], // 支藏干 不包括第1个
      zhiCangGanShiSheng: [], // 支藏干十神 不包括第1个
    },
  });
  const SJList = useMemo(() => {
    return [
      siJuYunData && siJuYunData.liunian,
      siJuYunData && siJuYunData.dayun,
      siJuData.year,
      siJuData.month,
      siJuData.day,
      siJuData.time,
    ];
  }, [siJuYunData, siJuData]);
  //大运流年流月 数据

  const [selectType, setSelectType] = useState(1);
  const selectTypeList = [
    { label: "阳历", value: 1 },
    { label: "阴历", value: 2 },
    { label: "四柱", value: 3 },
  ];
  const [time, setTime] = useState({
    year: 2020, // 1993
    month: 7, // 7
    day: 23, // 20
    hour: 4, // 22
    min: 0,
    sec: 0,
  });
  const [paipan, setPaipan] = useState(false); // 年月日时分秒 男 1 女 2
  const [xingbie, setXingbie] = useState(1); // 年月日时分秒 男 1 女 2
  // 当前排盘时间
  const [paitime, setPaiTime] = useState(time);
  const [paixingbie, setPaiXingbie] = useState(xingbie); // 年月日时分秒 男 1 女 2
  const [paiselectType, setPaiSelectType] = useState(selectType); // 阳历或阴历

  const selectLiunian = (dayunindex, liunianindex) => {
    if (dayunindex == 0) return;
    console.log("dayunIndex,liuIndex", dayunindex, liunianindex);
    const dayun = getCurYunAndLiunianItem(
      dayunlist[dayunindex],
      dayunlist[dayunindex].liunian[liunianindex]
    );
    setCurYunIndex({ dayunindex, liunianindex });
    setSiJuYunData(dayun);
  };

  const nextLiunian = (step) => {
    curYunIndex.liunianindex = curYunIndex.liunianindex + step;

    if (curYunIndex.liunianindex > 9) {
      curYunIndex.liunianindex = 0;
      curYunIndex.dayunindex = curYunIndex.dayunindex + 1;
      if (curYunIndex.dayunindex > 9) curYunIndex.dayunindex = 1;
    }

    if (curYunIndex.liunianindex < 0) {
      curYunIndex.liunianindex = 9;
      curYunIndex.dayunindex = curYunIndex.dayunindex - 1;
      if (curYunIndex.dayunindex < 1) curYunIndex.dayunindex = 1;
    }
    // setCurYunIndex(Object.assign({}, curYunIndex));
    selectLiunian(curYunIndex.dayunindex, curYunIndex.liunianindex);
  };
  const nextDayun = (step) => {
    curYunIndex.dayunindex = curYunIndex.dayunindex + step;
    if (curYunIndex.dayunindex > 9) {
      curYunIndex.dayunindex = 1;
    }
    if (curYunIndex.dayunindex < 1) {
      curYunIndex.dayunindex = 1;
    }
    curYunIndex.liunianindex = 0;

    selectLiunian(curYunIndex.dayunindex, curYunIndex.liunianindex);
  };

  const paiPaZi = () => {
    // 保存当前的排盘 时间，性别，阳历或阴历

    // 四柱推算时间
    setError('');
    let tmpselectType=0;
    let tmptime;
    if (selectType==3) {
      console.log(sizhu);
      const res=sijuToTime(sizhu,setSizhu);
      if (res.ok) {
        tmpselectType=1;
        tmptime =res.time;
        setTime(tmptime);
      } else {
        setError(res.err);
        console.log(res.err);
         return;
      }
     
    }
    const data = getAllData(tmpselectType || selectType, tmptime || time, xingbie);

    setDaYunList(data.yundata);
    setSiJuData(data.sijudata);

    setPaiTime(Object.assign({}, tmptime || time));
    setPaiXingbie(xingbie);
    setPaiSelectType(selectType);
    console.log("data.info", data.info);
    setInfo(Object.assign({}, data.info));
    console.log("data.curyun", data.curyun);
    setCurYunIndex(
      Object.assign(
        {},
        {
          dayunindex: data.curyun.dayunindex,
          liunianindex: data.curyun.liunianindex,
        }
      )
    );
    setSiJuYunData(data.curyun);
    setPaipan(true);
  };

  const Liunian = ({ liunians, dayunindex, selectItem, colSpan }) => {
    return liunians.map((lrow, index) => (
      <td
        onClick={() => !isMobile && selectItem(dayunindex, index)}
        colSpan={colSpan}
        key={index}
        style={{
          padding: 0,
          backgroundColor:
            curYunIndex.liunianindex == index &&
            curYunIndex.dayunindex == dayunindex
              ? "#f0f0f0"
              : "",
          verticalAlign: "top",
          cursor: dayunindex ? "pointer" : "",
        }}
      >
        <Typography align="center">
          {!isMobile &&
            lrow.year &&
            lrow.year.toString().slice(-2) + "年" + lrow.age + "岁"}
          {isMobile &&
            lrow.year &&
            lrow.year.toString().slice(-2) + "年" + lrow.age + "岁"}
        </Typography>
        <DaYunLiuNianBaseItem
          row={lrow}
          onClick={() => selectItem(dayunindex, index)}
          mobile={isMobile}
        ></DaYunLiuNianBaseItem>

        <XingCPoHai row={lrow} mobile={isMobile}></XingCPoHai>
      </td>
    ));
  };
  const KongLiunian = ({ num, colSpan }) => {
    const arrs = [];
    for (let index = 0; index < num; index++) {
      arrs.push(index);
    }
    return arrs.map((lrow, index) => (
      <td style={{ padding: 0 }} colSpan={colSpan} key={index}></td>
    ));
  };
  const XingCPoHaiTP = ({ row }) => {
    return (
      <>
        <Typography align="center" level="body" sx={{ mt: 0 }}>
          {row.ganhcxinghai && row.ganhcxinghai.length > 0 && "天干"}
          <Stack spacing={0}>
            {row.ganhcxinghai &&
              row.ganhcxinghai.map((item, i) => (
                <Typography key={i} textColor={WuXingColorList[item.wuxing]}>
                  {item.display}
                </Typography>
              ))}
          </Stack>
        </Typography>

        <Typography align="center" level="body" sx={{ mt: 1 }}>
          {row.zhihcxinghai && row.zhihcxinghai.length > 0 && "地支"}
          <Stack spacing={0}>
            {row.zhihcxinghai &&
              row.zhihcxinghai.map((item, i) => (
                <Typography key={i} textColor={WuXingColorList[item.wuxing]}>
                  {item.display}
                </Typography>
              ))}
          </Stack>
        </Typography>
      </>
    );
  };
  const XingCPoHaiPC = ({ row }) => {
    return (
      <>
        <Typography align="center" level="body5" sx={{ mt: 1 }}>
          <Stack spacing={0}>
            {row.ganhcxinghai &&
              row.ganhcxinghai.map((item, i) => (
                <Typography key={i} textColor={WuXingColorList[item.wuxing]}>
                  {item.display2}
                </Typography>
              ))}
          </Stack>
        </Typography>

        <Typography align="center" level="body5" sx={{ mt: 2 }}>
          <Stack spacing={0}>
            {row.zhihcxinghai &&
              row.zhihcxinghai.map((item, i) => (
                <Typography key={i} textColor={WuXingColorList[item.wuxing]}>
                  {item.display2}
                </Typography>
              ))}
          </Stack>
        </Typography>
      </>
    );
  };
  const XingCPoHai = ({ row, mobile }) => {
    return (
      <>
        {!mobile && <XingCPoHaiPC row={row}></XingCPoHaiPC>}
        {mobile && (
          <PopupState variant="popover">
            {(popupState) => (
              <Box sx={{ m: 0, p: 0 }}>
                <Box sx={{ m: 0, p: 0 }} {...bindTrigger(popupState)}>
                  <Typography
                    align="center"
                    level="body5"
                    sx={{ m: 0, mb: 0, p: 0, fontWeight: "bold" }}
                  >
                    <Stack sx={{ m: 0, p: 0 }}>
                      {row.ganhcxinghai &&
                        row.ganhcxinghai.map((item, i) => (
                          <Typography
                            key={"gan" + i}
                            sx={{ m: 0, p: 0 }}
                            textColor={WuXingColorList[item.wuxing]}
                          >
                            {item.display2.slice(-5)}
                          </Typography>
                        ))}
                    </Stack>
                  </Typography>
                  <Typography
                    align="center"
                    level="body5"
                    sx={{ m: 0, mt: 0, p: 0 }}
                  >
                    <Stack sx={{ m: 0, p: 0 }}>
                      {row.zhihcxinghai &&
                        row.zhihcxinghai.map((item, i) => (
                          <Typography
                            sx={{ m: 0, p: 0 }}
                            key={"zhi" + i}
                            textColor={WuXingColorList[item.wuxing]}
                          >
                            {item.display2.slice(-5)}
                          </Typography>
                        ))}
                    </Stack>
                  </Typography>
                </Box>

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <XingCPoHaiTP sx={{ m: 1 }} row={row}></XingCPoHaiTP>
                </Popover>
              </Box>
            )}
          </PopupState>
        )}
      </>
    );
  };
  const DaYunLiuNianBaseItem = ({ row, fontWeight, mobile, onClick }) => {
    return (
      <>
        {mobile && (
          <Box onClick={onClick}>
            {" "}
            <Typography sx={{ fontWeight }} align="center">
              <Typography textColor={WuXingColorList[row.ganWuXing]}>
                {row.gan}
              </Typography>{" "}
              <Typography textColor={WuXingColorList[row.zhiWuXing]}>
                {row.zhi}
              </Typography>
            </Typography>
            <Typography sx={{ fontWeight }} align="center">
              <Typography textColor={WuXingColorList[row.ganWuXing]}>
                {ShiShengJCList[row.ganShiSheng]}
              </Typography>{" "}
              <Typography textColor={WuXingColorList[row.zhiWuXing]}>
                {ShiShengJCList[row.zhiShiSheng]}
              </Typography>
            </Typography>
            <Typography align="center">
              <Typography textColor={XiJiColorList[row.ganXiji.title]}>
                {row.ganXiji.title}
              </Typography>{" "}
              <Typography textColor={XiJiColorList[row.zhiXiji.title]}>
                {row.zhiXiji.title}
              </Typography>
            </Typography>
          </Box>
        )}
        {!mobile && (
          <>
            <Typography
              align="center"
              textColor={WuXingColorList[row.ganWuXing]}
            >
              {row.gan}
              {row.ganWuXing} {row.ganShiSheng}
              {"."}
              {row.ganXiji.title}
            </Typography>
            <Typography
              align="center"
              textColor={WuXingColorList[row.zhiWuXing]}
            >
              {row.zhi}
              {row.zhiWuXing} {row.zhiShiSheng}
              {"."}
              {row.zhiXiji.title}
            </Typography>
          </>
        )}
      </>
    );
  };

  const selectHandle1 = (e, value) => {
    console.log(value);
    const value1 = area.find((item) => item.id == value);
    setAreaList2(value1.subitems || []);

    if (value1.subitems && value1.subitems.length > 0) {
      setAreaList3(value1.subitems[0].subitems || []);
    } else {
      setAreaList3([]);
    }

    // const beijingTime = "2023-09-12 12:00:00"; // 北京时间
    // const zhentime= suncalc.getTimes(new Date("2023-09-12 19:10:00"),1*value1.lat-39.904989,1*value1.lng- 116.405285);//1*value1.lat,1*value1.lang,0);
    // console.log(zhentime.solarNoon);
    // console.log(new Date("2023-09-12 19:10:00"));
    setLanglat({ lat: value1.lat, lng: value1.lng });
  };
  const selectHandle2 = (e, value) => {
    //console.log(value)
    const value2 = areaList2.find((item) => item.id == value);
    if (value2 && value2.subitems && value2.subitems.length > 0) {
      setAreaList3(value2.subitems || []);
    } else {
      setAreaList3([]);
    }
    value2 && setLanglat({ lat: value2.lat, lng: value2.lng });
    if (value2) {
      const zhentime = suncalc.getTimes(
        new Date(),
        1 * value2.lat,
        1 * value2.lng
      ); //1*value1.lat,1*value1.lang,0);
      console.log(zhentime.solarNoon);
    }
  };
  const selectHandle3 = (e, value) => {
    //console.log(value)
    const value3 = areaList3.find((item) => item.id == value);

    value3 && setLanglat({ lat: value3.lat, lng: value3.lng });
    if (value3) {
      const zhentime = suncalc.getTimes(
        new Date(),
        1 * value3.lat,
        1 * value3.lng
      ); //1*value1.lat,1*value1.lang,0);
      console.log(zhentime.solarNoon);
    }
  };
  return (
    <Grid container spacing={0} sx={{ flexGrow: 1 }}>
      <Grid xs={12} md={3}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <SelectRadio
            type={selectType}
            setType={setSelectType}
            typeList={selectTypeList}
          ></SelectRadio>
        </Stack>
      </Grid>
      <Grid xs={12} md={5}>
        {selectType==3?<InputSiZhu sizhu={sizhu} setSizhu={setSizhu}></InputSiZhu>:<InputTime time={time} setTime={setTime}></InputTime>}
      </Grid>
      <Grid xs={12} md={3}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <RadioGroup
            value={xingbie}
            onChange={(event) => setXingbie(event.target.value)}
            orientation="horizontal"
            sx={{ my: 1 }}
          >
            <Radio value={1} label="男" />
            <Radio value={2} label="女" />
          </RadioGroup>
          <Button sx={{ width: 220 }} onClick={paiPaZi} size="lg">
            排八字
          </Button>
        </Stack>
      </Grid>
      {error && <Grid xs={12}>
      <Alert  color="warning">{error} </Alert>
     </Grid>}
{/*       <Grid xs={12} md={4}>
        <Select onChange={selectHandle1} defaultValue={1}>
          {area.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Grid>
      <Grid xs={12} md={4}>
        <Select onChange={selectHandle2} defaultValue={2}>
          {areaList2.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Grid>
      <Grid xs={12} md={4}>
        <Select onChange={selectHandle3} defaultValue={3}>
          {areaList3.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Grid> */}
      {paipan && (
        <>
          <Grid xs={12}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>排盘信息：</Typography>{" "}
              <Typography>
                {" "}
                {paixingbie == 1 ? "男：乾造" : "女：坤造"}{" "}
              </Typography>
              <Typography> {paiselectType == 1 ? "阳历" : "阴历"} </Typography>
              <Typography>
                {paitime.year}年{paitime.month}月{paitime.day}日{paitime.hour}时
              </Typography>
            </Stack>
          </Grid>

          <Grid xs={12}>
            <Sheet>
              <Table size="md" stripe="even" variant="outlined">
                <thead>
                  <tr>
                    <th>日期</th>
                    <th>
                      {isMobile && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <IconButton
                            aria-label="上一年"
                            color="primary"
                            size="small"
                            sx={{ p: 0, m: 0 }}
                            onClick={() => nextLiunian(-1)}
                          >
                            <ChevronLeftIcon></ChevronLeftIcon>
                          </IconButton>
                          <IconButton
                            aria-label="上一年"
                            color="primary"
                            size="small"
                            sx={{ p: 0, m: 0 }}
                            onClick={() => nextLiunian(1)}
                          >
                            <ChevronRightIcon></ChevronRightIcon>
                          </IconButton>
                        </Stack>
                      )}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        {!isMobile && (
                          <IconButton
                            aria-label="上一年"
                            color="primary"
                            size="small"
                            sx={{ p: 0, m: 0 }}
                            onClick={() => nextLiunian(-1)}
                          >
                            <ChevronLeftIcon></ChevronLeftIcon>
                          </IconButton>
                        )}
                        <Typography level="body">
                          {siJuYunData.liunian.year}
                        </Typography>
                        {!isMobile && (
                          <IconButton
                            aria-label="上一年"
                            color="primary"
                            size="small"
                            sx={{ p: 0, m: 0 }}
                            onClick={() => nextLiunian(1)}
                          >
                            <ChevronRightIcon></ChevronRightIcon>
                          </IconButton>
                        )}
                      </Stack>
                      <Typography> 流年</Typography>
                    </th>
                    <th>
                      {isMobile && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <IconButton
                            aria-label="上运"
                            color="primary"
                            sx={{ p: 0, m: 0 }}
                            size="small"
                            edge="start"
                            onClick={() => nextDayun(-1)}
                          >
                            <ChevronLeftIcon></ChevronLeftIcon>
                          </IconButton>
                          <IconButton
                            aria-label="上运"
                            color="primary"
                            size="small"
                            sx={{ p: 0, m: 0 }}
                            onClick={() => nextDayun(1)}
                          >
                            <ChevronRightIcon></ChevronRightIcon>
                          </IconButton>
                        </Stack>
                      )}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        {!isMobile && (
                          <IconButton
                            aria-label="上运"
                            color="primary"
                            sx={{ p: 0, m: 0 }}
                            size="small"
                            edge="start"
                            onClick={() => nextDayun(-1)}
                          >
                            <ChevronLeftIcon></ChevronLeftIcon>
                          </IconButton>
                        )}
                        <Typography level="body">
                          {siJuYunData.dayun.year}
                        </Typography>
                        {!isMobile && (
                          <IconButton
                            aria-label="上运"
                            color="primary"
                            size="small"
                            sx={{ p: 0, m: 0 }}
                            onClick={() => nextDayun(1)}
                          >
                            <ChevronRightIcon></ChevronRightIcon>
                          </IconButton>
                        )}
                      </Stack>
                      <Typography>大运</Typography>
                    </th>
                    <th>
                      <Typography level="body">{paitime.year}</Typography>

                      <Typography>年</Typography>
                    </th>
                    <th>
                      <Typography level="body">{paitime.month}</Typography>
                      <Typography>月</Typography>
                    </th>
                    <th>
                      <Typography level="body">{paitime.day}</Typography>
                      <Typography>日</Typography>
                    </th>
                    <th>
                      <Typography level="body">{paitime.hour}</Typography>
                      <Typography>时</Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>主星</td>
                    {SJList.map((sjitem, sjindex) => (
                      <>
                        {sjindex != 4 && (
                          <td>
                            <Typography level="h7">
                              {sjitem && sjitem.ganShiSheng}
                            </Typography>
                            <Typography level="body3">
                              {sjitem && sjitem.ganXiji && sjitem.ganXiji.title}
                            </Typography>
                          </td>
                        )}

                        {sjindex == 4 && (
                          <td style={{ padding: 1 }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {!isMobile && (paixingbie == 1 ? "男" : "女")}
                              {!isMobile && sjitem.ganShiSheng}
                            </Typography>
                            {info.shenqaingruo && (
                              <Typography sx={{ fontWeight: "bold" }}>
                                {isMobile && (paixingbie == 1 ? "男" : "女")}
                                {info.shenqaingruo.title}
                                {"("}
                                {info.shenqaingruo.core}
                                {"分)"}
                              </Typography>
                            )}
                          </td>
                        )}
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td>合冲刑害</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td style={{ padding: 1, verticalAlign: "bottom" }}>
                        {sjitem.ganhcxinghai &&
                          sjitem.ganhcxinghai.map((item, i) => (
                            <Typography
                              level="body4"
                              textColor={WuXingColorList[item.wuxing]}
                            >
                              {item.display}
                            </Typography>
                          ))}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>天干</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td>
                        <Typography
                          level="h4"
                          textColor={WuXingColorList[sjitem.ganWuXing]}
                        >
                          {sjitem.gan}
                        </Typography>
                        <Typography level="body3">
                          {sjitem.ganYouGen && sjitem.ganYouGen.title2} {' '}
                          
                          {(sjitem.ganZuoLu && sjitem.ganZuoLu.title) || (sjitem.ganDeLu && sjitem.ganDeLu.title)}
                        </Typography>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>地支</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td>
                        <Typography
                          level="h4"
                          textColor={WuXingColorList[sjitem.zhiWuXing]}
                        >
                          {sjitem.zhi}
                        </Typography>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>合冲刑害</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td style={{ padding: 1, verticalAlign: "top" }}>
                        {sjitem.zhihcxinghai &&
                          sjitem.zhihcxinghai.map((item, i) => (
                            <Typography
                              level="body4"
                              textColor={WuXingColorList[item.wuxing]}
                            >
                              {item.display}
                            </Typography>
                          ))}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>藏干</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td>
                        <Stack sx={{ height: "100%" }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Typography
                              sx={{ fontWeight: "bold" }}
                              textColor={WuXingColorList[sjitem.zhiWuXing]}
                            >
                              {" "}
                              {sjitem.zhiGan}
                              {sjitem.zhiWuXing}
                            </Typography>
                          </Stack>
                          {sjitem.zhiCangGan &&
                            sjitem.zhiCangGan.map((item, i) => (
                              <Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Typography
                                    textColor={
                                      WuXingColorList[
                                        sjitem.zhiCangGanWuXing[i]
                                      ]
                                    }
                                  >
                                    {" "}
                                    {item}
                                    {sjitem.zhiCangGanWuXing[i]}
                                  </Typography>
                                </Stack>
                              </Stack>
                            ))}
                        </Stack>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>副星</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td>
                        {sjitem && (
                          <Stack sx={{ height: "100%" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                {sjitem.zhiShiSheng}
                                {"." + sjitem &&
                                  sjitem.zhiXiji &&
                                  sjitem.zhiXiji.title}
                              </Typography>
                            </Stack>
                            {sjitem.zhiCangGanShiSheng &&
                              sjitem.zhiCangGanShiSheng.map((item, i) => (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Typography>{item}</Typography>
                                </Stack>
                              ))}
                          </Stack>
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>星运</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td> {sjitem && sjitem.diShi} </td>
                    ))}
                  </tr>
                  <tr>
                    <td>空亡</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td> {sjitem && sjitem.xunKong} </td>
                    ))}
                  </tr>
                  <tr>
                    <td>纳音</td>
                    {SJList.map((sjitem, sjindex) => (
                      <td> {sjitem && sjitem.naYin} </td>
                    ))}
                  </tr>

                  {/*           <tr>
            <td>神煞</td>
            <td>月德贵人</td>
            <td>月德贵人</td>
            <td>月德贵人</td>
            <td>月德贵人</td>
            <td>月德贵人</td>
            <td>月德贵人</td>
          </tr> */}
                </tbody>
              </Table>
            </Sheet>
          </Grid>

          <Grid xs={12}>
            <Typography>大运流年：</Typography>{" "}
          </Grid>
          <Grid xs={12}>
            <Table style={{ border: "1px solid black" }} borderAxis="both">
              <thead>
                <tr>
                  {isMobile && (
                    <>
                      <th style={{ width: "7%" }}>大运</th>
                      <th>流年</th>
                      <th>流年</th>
                      <th>流年</th>
                      <th>流年</th>
                      <th>流年</th>
                    </>
                  )}
                  {!isMobile && (
                    <>
                      <th style={{ width: "7%" }}>大运</th>
                      <th>流年1</th>
                      <th>流年2</th>
                      <th>流年3</th>
                      <th>流年4</th>
                      <th>流年5</th>
                      <th>流年6</th>
                      <th>流年7</th>
                      <th>流年8</th>
                      <th>流年9</th>
                      <th>流年10</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {dayunlist.map((yrow, index) => (
                  <>
                    {!isMobile && (
                      <tr key={index}>
                        <td
                          style={{ verticalAlign: "top", cursor: "pointer" }}
                          onClick={() => selectLiunian(index, 0)}
                        >
                          <Typography
                            align="center"
                            sx={{ fontWeight: "bold" }}
                          >
                            {yrow.year + "年" + yrow.age + "岁"}
                          </Typography>
                          {index != 0 && (
                            <DaYunLiuNianBaseItem
                              row={yrow}
                              mobile={false}
                              fontWeight={"bold"}
                            ></DaYunLiuNianBaseItem>
                          )}

                          <Typography
                            align="center"
                            level="body5"
                            sx={{ mt: 1 }}
                          >
                            <Stack spacing={0}>
                              {yrow.ganhcxinghai &&
                                yrow.ganhcxinghai.map((item, i) => (
                                  <Typography
                                    textColor={WuXingColorList[item.wuxing]}
                                  >
                                    {item.display2}
                                  </Typography>
                                ))}
                            </Stack>
                          </Typography>

                          <Typography
                            align="center"
                            level="body5"
                            sx={{ mt: 2 }}
                          >
                            <Stack spacing={0}>
                              {yrow.zhihcxinghai &&
                                yrow.zhihcxinghai.map((item, i) => (
                                  <Typography
                                    textColor={WuXingColorList[item.wuxing]}
                                  >
                                    {item.display2}
                                  </Typography>
                                ))}
                            </Stack>
                          </Typography>
                        </td>
                        <KongLiunian
                          num={10 - yrow.liunian.length}
                        ></KongLiunian>
                        <Liunian
                          liunians={yrow.liunian}
                          dayunindex={index}
                          selectItem={selectLiunian}
                        ></Liunian>
                      </tr>
                    )}
                    {isMobile && (
                      <>
                        <tr key={index}>
                          <td
                            rowSpan={2}
                            key={"td" + index}
                            style={{
                              width: "7%",
                              padding: 1,
                              verticalAlign: "top",
                              cursor: "pointer",
                            }}
                          >
                            <Typography
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              {yrow.year + ""}
                            </Typography>
                            <Typography
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              {yrow.age + "岁"}
                            </Typography>
                            {index != 0 && (
                              <DaYunLiuNianBaseItem
                                row={yrow}
                                onClick={() => selectLiunian(index, 0)}
                                fontWeight={"bold"}
                                mobile={isMobile}
                              ></DaYunLiuNianBaseItem>
                            )}
                            <XingCPoHai
                              key={"xcph" + index}
                              row={yrow}
                              mobile={isMobile}
                            ></XingCPoHai>
                          </td>
                          <KongLiunian
                            num={
                              yrow.liunian.length >= 5
                                ? 0
                                : 5 - yrow.liunian.length
                            }
                          ></KongLiunian>
                          <Liunian
                            liunians={
                              yrow.liunian.length >= 5
                                ? yrow.liunian.slice(0, yrow.liunian.length - 5)
                                : []
                            }
                            dayunindex={index}
                            selectItem={selectLiunian}
                          ></Liunian>
                        </tr>
                        <tr key={10000 * index + 1}>
                          <KongLiunian
                            num={5 - yrow.liunian.length}
                          ></KongLiunian>
                          <Liunian
                            liunians={
                              yrow.liunian.length >= 5
                                ? yrow.liunian.slice(
                                    yrow.liunian.length - 5,
                                    yrow.liunian.length
                                  )
                                : []
                            }
                            dayunindex={index}
                            selectItem={selectLiunian}
                          ></Liunian>
                        </tr>
                      </>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Bazi;
