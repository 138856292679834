import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/joy/styles';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import App from './App';

const rootElement = document.querySelector("#root");
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
      <Router>
        <App />
        </Router>
      </StyledEngineProvider>
    </React.StrictMode>
  );
}