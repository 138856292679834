import {
  BrowserRouter as Router,
  useNavigate,
  Routes,
  Route,
} from "react-router-dom";
// import Routes from './routes';
import { useLocation } from "react-router-dom";

import React, { useState, useEffect } from "react";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import Stack from "@mui/joy/Stack";

import TabPanel from "@mui/joy/TabPanel";
import MeiHua from "./MeiHua";
import Qimen from "./Qimen";
import Bazi from "./Bazi";

// import Routes from './routes';

const App = () => {
  const location = useLocation();
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  function handleClick(event, value) {
    setIndex(value);
    if (value == 0) navigate("/");
    if (value == 1) navigate("/meihua");
    if (value == 2) navigate("/qimen");
  }
  useEffect(() => {
    if (location.pathname == "/qimen") {
      setIndex(2);
    }
    if (location.pathname == "/meihua") {
      setIndex(1);
    }
    if (location.pathname == "/") {
      setIndex(0);
    }
  }, []);
  return (
    <Stack spacing={1}>
      <Tabs
        aria-label="Soft tabs"
        value={index}
        onChange={handleClick}
        sx={{ borderRadius: "lg" }}
      >
        <TabList variant="soft">
          <Tab
            value={0}
            variant={index === 0 ? "solid" : "plain"}
            color={index === 0 ? "primary" : "neutral"}
          >
            八字
          </Tab>
          <Tab
            value={1}
            variant={index === 1 ? "solid" : "plain"}
            color={index === 1 ? "primary" : "neutral"}
          >
            梅花
          </Tab>
          <Tab
            value={2}
            variant={index === 2 ? "solid" : "plain"}
            color={index === 2 ? "primary" : "neutral"}
          >
            奇门
          </Tab>
        </TabList>
      </Tabs>
      <Routes>
        <Route path="/meihua" element={<MeiHua />} />
        <Route path="/" element={<Bazi />} />
        <Route path="/bazi" element={<Bazi />} />
        <Route path="/qimen" element={<Qimen />} />
      </Routes>
    </Stack>
  );
};

export default App;
