import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Autocomplete,
  Stack,
  Typography,
  Input,
} from "@mui/joy";
import Radio, { radioClasses } from "@mui/joy/Radio";
import FormLabel from "@mui/joy/FormLabel";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
const InputTime2 = ({ time, setTime, min, sec }) => {
  /*
    const [time, setTime] = useState({
        year: 2020, // 1993
        month: 7, // 7
        day: 23, // 20
        hour: 4, // 22
        min: 0,
        sec: 0,
      });
*/
  const [curtime, setCurTime] = useState(new Date());
  const [years, setyears] = useState([]);
  const [months, setmonths] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ]);
  const [days, setdays] = useState([]);
  const [hours, sethours] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 22, 23,
    0,
  ]);
  const [minutes, setminutes] = useState([]);

  const onValueChange = (field, value) => {
    time[field] = 1 * value;
    console.log(value, "time", time);
    setTime(time);
  };

  useEffect(() => {
    const tmpminutes = [];
    const tmpdays = [];
    for (let index = 0; index < 200; index++) {
      years.push(curtime.getFullYear() - 50 + index);
      if (index < 60) tmpminutes.push(index);
      if (index < 31) tmpdays.push(index + 1);
    }
    setyears([...years]);
    setminutes([...tmpminutes]);
    setdays([...tmpdays]);
  }, []);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Autocomplete
        required
        sx={{ width: 150 }}
        value={time.year}
        onChange={(e) => onValueChange("year", e.target.value)}
        options={years}
      ></Autocomplete>
      <Typography>年</Typography>
      <Autocomplete
        required
        sx={{ width: 120 }}
        value={time.month}
        onChange={(e) => onValueChange("month", e.target.value)}
        options={months}
      ></Autocomplete>
      <Typography>月</Typography>
      <Autocomplete
        required
        sx={{ width: 120 }}
        value={time.day}
        onChange={(e) => onValueChange("day", e.target.value)}
        options={days}
      ></Autocomplete>
      <Typography>日</Typography>
      <Autocomplete
        getOptionLabel={(option) => "" + option}
        sx={{ width: 120 }}
        required
        value={time.hour}
        onChange={(e) => onValueChange("hour", e.target.value)}
        options={hours}
      ></Autocomplete>
      <Typography>时</Typography>

      {min && (
        <>
          <Autocomplete
            required
            getOptionLabel={(option) => "" + option}
            sx={{ width: 120 }}
            value={time.min}
            onChange={(e) => onValueChange("min", e.target.value)}
            options={minutes}
          ></Autocomplete>
          <Typography>分</Typography>
        </>
      )}
      {sec && (
        <>
          <Autocomplete
            required
            getOptionLabel={(option) => "" + option}
            sx={{ width: 120 }}
            value={time.sec}
            onChange={(e) => onValueChange("sec", e.target.value)}
            options={minutes}
          ></Autocomplete>
          <Typography>秒</Typography>
        </>
      )}
    </Stack>
  );
};

const InputTime = ({ time, setTime, min, sec }) => {
  const [year, setYear] = useState(2023);
  // time, setTime,
  /* 
    const [time, setTime] = useState({
        year: 2020, // 1993
        month: 7, // 7
        day: 23, // 20
        hour: 4, // 22
        min: 0,
        sec: 0,
      }); */

  const [curtime, setCurTime] = useState(new Date());
  const handleFocus = (event) => {
    event.target.select();
  };
  const onValueChange = (field, value) => {
    value = 1 * value;
    if (field == "year") {
      value = value < 1 ? 1 : value;
      value = value > 2100 ? 2100 : value;
    }
    if (field == "month") {
      value = value < 1 ? 1 : value;
      value = value > 12 ? 12 : value;
    }
    if (field == "day") {
      value = value < 1 ? 1 : value;
      value = value > 31 ? 31 : value;
    }
    if (field == "hour") {
      value = value < 0 ? 1 : value;
      value = value > 23 ? 23 : value;
    }
    if (field == "min") {
      value = value < 0 ? 1 : value;
      value = value > 59 ? 59 : value;
    }
    if (field == "sec") {
      value = value < 0 ? 1 : value;
      value = value > 59 ? 59 : value;
    }
    time[field] = 1 * value;
    setTime(Object.assign({}, time));
    console.log(value, "time", time);
  };

  return (
    <>
    <Stack direction="row" sx={{flexWrap:'wrap'}} alignItems="center" spacing={0}>
      <Input
        type="number"
        sx={{ width: 80 }}
        onFocus={handleFocus}
        onChange={(e) => onValueChange("year", e.target.value)}
        value={time.year}
        slotProps={{
          input: {
            min: 1900,
            max: 2100,
            step: 1,
          },
        }}
      />
      <Typography>年</Typography>
      <Input
        type="number"
        sx={{ width: 60 }}
        onFocus={handleFocus}
        value={time.month}
        onChange={(e) => onValueChange("month", e.target.value)}
        slotProps={{
          input: {
            min: 1,
            max: 12,
            step: 1,
          },
        }}
      ></Input><Typography>月</Typography>
      <Input
        type="number"
        required
        onFocus={handleFocus}
        sx={{ width: 60 }}
        value={time.day}
        slotProps={{
          input: {
            min: 1,
            max: 31,
            step: 1,
          },
        }}
        onChange={(e) => onValueChange("day", e.target.value)}
      ></Input><Typography>日</Typography>

      <Input
        type="number"
        sx={{ width: 60 }}
        required
        value={time.hour}
        onFocus={handleFocus}
        onChange={(e) => onValueChange("hour", e.target.value)}
      ></Input><Typography>时</Typography>
      
     
      {min && (
        <>
          <Input
            type="number"
            sx={{ width: 60 }}
            value={time.min}
            slotProps={{
              input: {
                min: 0,
                max: 59,
                step: 1,
              },
            }}
            onFocus={handleFocus}
            onChange={(e) => onValueChange("min", e.target.value)}
          ></Input>
          <Typography>分</Typography>
        </>
      )}
      {sec && (
        <>
          <Input
            type="number"
            sx={{ width: 60 }}
            value={time.sec}
            slotProps={{
              input: {
                min: 0,
                max: 59,
                step: 1,
              },
            }}
            onFocus={handleFocus}
            onChange={(e) => onValueChange("sec", e.target.value)}
          ></Input>
          <Typography>秒</Typography>
        </>
      )}
</Stack>

    </>
  );
};
export const SelectRadio = ({ type, setType, typeList }) => {
  const dftypelist = [
    { label: "取当前时间", value: 1 },
    { label: "输入时间", value: 2 },
  ];

  return (
    <RadioGroup
      aria-label="platform"
      value={type}
      onChange={(e) => setType(e.target.value)}
      defaultValue={1}
      overlay
      name="platform"
      sx={{
        flexDirection: "row",
        gap: 1,
        [`& .${radioClasses.checked}`]: {
          [`& .${radioClasses.action}`]: {
            inset: -1,
            border: "1px solid",
            borderColor: "primary.500",
          },
        },
        [`& .${radioClasses.radio}`]: {
          display: "contents",
          "& > svg": {
            zIndex: 2,
            position: "absolute",
            top: "-8px",
            right: "-8px",
            bgcolor: "background.body",
            borderRadius: "50%",
          },
        },
      }}
    >
      {(typeList || dftypelist).map((value) => (
        <Sheet
          key={value.value}
          variant="outlined"
          sx={{
            borderRadius: "md",
            bgcolor: "background.body",
            boxShadow: "sm",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 1,
            minWidth: 65,
          }}
        >
          <Radio
            id={value.label}
            value={value.value}
            checkedIcon={<CheckCircleRoundedIcon />}
          />
          <FormLabel htmlFor={value.label}>{value.label}</FormLabel>
        </Sheet>
      ))}
    </RadioGroup>
  );
};

export const SelectRadio2 = ({ type, setType, typeList }) => {
  const dftypelist = [
    { label: "取当前时间", value: 1 },
    { label: "输入时间", value: 2 },
  ];

  return (
    <RadioGroup
      value={type}
      onChange={(event) => setType(event.target.value)}
      orientation="horizontal"
      sx={{ my: 1 }}
    >
      {(typeList || dftypelist).map((value) => (
        <Radio value={value.value} label={value.label} />
      ))}
    </RadioGroup>
  );
};

export const AutoCurTime = ({ curtime, setCurTime, level }) => {
  const [isRunning, setIsRunning] = useState(true);
  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setCurTime(new Date());
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning]);

  const handleStopClick = () => {
    setIsRunning(!isRunning);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography level={level || "h6"}>
        {curtime.getFullYear()}年{curtime.getMonth() + 1}月{curtime.getDate()}日
        {curtime.getHours()}时{curtime.getMinutes()}分{curtime.getSeconds()}秒
      </Typography>
      <Button variant="outlined" onClick={handleStopClick}>
        {isRunning ? "停止" : "启动"}
      </Button>
    </Stack>
  );
};
export const InputMeiHuaNum = ({ nums, setNums }) => {
  const [minutes, setminutes] = useState([]);
  const handleFocus = (event) => {
    event.target.select();
  };
  const onValueChange = (field, value) => {
    value = 1 * value;
    value = value < 1 ? 1 : value;
    value = value > 999 ? 999 : value;

    nums[field] = 1 * value;
    setNums([...nums]);
    console.log(value, "nums", nums);
  };
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Typography>上卦</Typography>
      <Input
        type="number"
        sx={{ width: 75 }}
        onFocus={handleFocus}
        onChange={(e) => onValueChange(0, e.target.value)}
        value={nums[0]}
        slotProps={{
          input: {
            min: 1,
            max: 999,
            step: 1,
          },
        }}
      />

      <Typography>下卦</Typography>
      <Input
        type="number"
        sx={{ width: 75 }}
        onFocus={handleFocus}
        value={nums[1]}
        onChange={(e) => onValueChange(1, e.target.value)}
        slotProps={{
          input: {
            min: 1,
            max: 999,
            step: 1,
          },
        }}
      ></Input>
      <Typography>动爻</Typography>

      <Input
        type="number"
        required
        onFocus={handleFocus}
        sx={{ width: 75 }}
        value={nums[2]}
        slotProps={{
          input: {
            min: 1,
            max: 999,
            step: 1,
          },
        }}
        onChange={(e) => onValueChange(2, e.target.value)}
      ></Input>
    </Stack>
  );
};
// 四柱
export const InputSiZhu = ({ setSizhu }) => {
  const [nbsizhu, setnbsizhu] = useState('');
  const handleFocus = (event) => {
    event.target.select();
  };
  const onValueChange = (field, value) => {
    setnbsizhu(value);
    let str = value.replace(/[\s;,.\n\r]/g, '');
    str = str.substr(0, 8);

    const newsizhu=[];
    for (let i = 0; i < str.length; i += 2) {
      newsizhu.push(str.substr(i, 2));
    }
    for (let i = newsizhu.length; i < 4; i++) {
      newsizhu.push('');
    }
    setSizhu([...newsizhu]);
  };
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Typography>四柱干支:</Typography>
      <Input
        sx={{ width: 250 }}
        onFocus={handleFocus}
        onChange={(e) => onValueChange(0, e.target.value)}
        value={nbsizhu}
      />

    </Stack>
  );
};
export default InputTime;
