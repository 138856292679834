const YY = {
    yin: { name: "阴", value: "yin", xuhao: 0 },
    yang: { name: "阳", value: "yang", xuhao: 1 },
  };
  
  const JIAZI = [
    '甲子', '乙丑', '丙寅', '丁卯', '戊辰', '己巳', '庚午', '辛未', '壬申', '癸酉',
    '甲戌', '乙亥', '丙子', '丁丑', '戊寅', '己卯', '庚辰', '辛巳', '壬午', '癸未',
    '甲申', '乙酉', '丙戌', '丁亥', '戊子', '己丑', '庚寅', '辛卯', '壬辰', '癸巳',
    '甲午', '乙未', '丙申', '丁酉', '戊戌', '己亥', '庚子', '辛丑', '壬寅', '癸卯',
    '甲辰', '乙巳', '丙午', '丁未', '戊申', '己酉', '庚戌', '辛亥', '壬子', '癸丑',
    '甲寅', '乙卯', '丙辰', '丁巳', '戊午', '己未', '庚申', '辛酉', '壬戌', '癸亥'
  ];


  const SHISHEN = ['正印', '偏印', '比肩', '劫财', '伤官', '食神', '正财', '偏财', '正官', '偏官'];
  const SHISHENJ = ['印枭', '比劫', '食伤', '才财', '官杀'];
  // 比肩、劫财、伤官、食神、正财、偏财、正官、偏官、正印、偏印
  // 方位
  const FX = {
    d: { name: "东", value: "d" },
    db: { name: "东北", value: "db" },
    dn: { name: "东南", value: "dn" },
    x: { name: "西", value: "x" },
    xb: { name: "西北", value: "xb" },
    xn: { name: "西南", value: "xn" },
    n: { name: "南", value: "n" },
    b: { name: "北", value: "b" },
    z: { name: "中", value: "z" },
  };
  // 五行
  const WUXIN = {
    mu: { name: "木", ke: "tu", sheng: "huo", xuhao: 1, color: "#5c7a29" },
    huo: { name: "火", ke: "jin", sheng: "tu", xuhao: 2, color: "#c63c26" },
    tu: { name: "土", ke: "shui", sheng: "jin", xuhao: 3, color: "#ba8448" },
    jin: { name: "金", ke: "mu", sheng: "shui", xuhao: 4, color: "#c37e00" },
    shui: { name: "水", ke: "huo", sheng: "mu", xuhao: 5, color: "#145b7d" },
  };

  const XT_BG_VALUE_KEY = {
  x111:'x1',
  x011:'x2',
  x101:'x3',
  x001:'x4',
  x110:'x5',
  x010:'x6',
  x100:'x7',
  x000:'x8'
}

  const XT_BG = {
    x1: {
      name: "乾",
      yyvalue: [1, 1, 1],
      xuhao: 1,
      wx: "jin",
      yy: "yang",
      ren: "父亲",
      fw: "xb",
      color: "#c37e00",
    },
    x2: {
      name: "兑",
      yyvalue: [0, 1, 1],
      xuhao: 2,
      wx: "jin",
      yy: "yin",
      ren: "少女",
      fw: "x",
      color: "#c37e00",
    },
    x3: {
      name: "离",
      yyvalue: [1, 0, 1],
      xuhao: 3,
      wx: "hou",
      yy: "yang",
      ren: "中女",
      fw: "n",
      color: "#c63c26",
    },
    x4: {
      name: "震",
      yyvalue: [0, 0, 1],
      xuhao: 4,
      wx: "mu",
      yy: "yang",
      ren: "长男",
      fw: "d",
      color: "#1d953f",
    },
    x5: {
      name: "巽",
      yyvalue: [1, 1, 0],
      xuhao: 5,
      wx: "mu",
      yy: "yin",
      ren: "长女",
      fw: "dn",
      color: "#7fb80e",
    },
    x6: {
      name: "坎",
      yyvalue: [0, 1, 0],
      xuhao: 6,
      wx: "shui",
      yy: "yin",
      ren: "中男",
      fw: "db",
      color: "#145b7d",
    },
    x7: {
      name: "艮",
      yyvalue: [1, 0, 0],
      xuhao: 7,
      wx: "tu",
      yy: "yang",
      ren: "少男",
      fw: "xb",
      color: "#281f1d",
    },
    x8: {
      name: "坤",
      yyvalue: [0, 0, 0],
      xuhao: 8,
      wx: "tu",
      yy: "yin",
      ren: "母亲",
      fw: "xn",
      color: "#ba8448",
    },
  };
  // 天干
  const TG = {
    t1: { name: "甲", xuhao: 1, yy: "yang", wx: "mu" },
    t2: { name: "乙", xuhao: 2, yy: "yin", wx: "mu" },
    t3: { name: "丙", xuhao: 3, yy: "yang", wx: "huo" },
    t4: { name: "丁", xuhao: 4, yy: "yin", wx: "huo" },
    t5: { name: "戊", xuhao: 5, yy: "yang", wx: "tu" },
    t6: { name: "己", xuhao: 6, yy: "yin", wx: "tu" },
    t7: { name: "庚", xuhao: 7, yy: "yang", wx: "jin" },
    t8: { name: "辛", xuhao: 8, yy: "yin", wx: "jin" },
    t9: { name: "壬", xuhao: 9, yy: "yang", wx: "shui" },
    t10: { name: "癸", xuhao: 10, yy: "yin", wx: "shui" },
  };
  //地支
  const DZ = {
    d1: { name: "寅", xuhao: 1, yy: "yang", wx: "mu" },
    d2: { name: "卯", xuhao: 2, yy: "yin", wx: "mu" },
    d3: { name: "辰", xuhao: 3, yy: "yang", wx: "tu" },
    d4: { name: "巳", xuhao: 4, yy: "yin", wx: "huo" },
    d5: { name: "午", xuhao: 5, yy: "yang", wx: "huo" },
    d6: { name: "未", xuhao: 6, yy: "yin", wx: "tu" },
    d7: { name: "申", xuhao: 7, yy: "yang", wx: "jin" },
    d8: { name: "酉", xuhao: 8, yy: "yin", wx: "jin" },
    d9: { name: "戌", xuhao: 9, yy: "yang", wx: "tu" },
    d10: { name: "亥", xuhao: 10, yy: "yin", wx: "shui" },
    d11: { name: "子", xuhao: 11, yy: "yang", wx: "shui" },
    d12: { name: "丑", xuhao: 12, yy: "yin", wx: "tu" }
  };

  const GUA64 ={
  "0b000000": "坤为地",
  "0b000001": "地雷复",
  "0b000010": "地水师",
  "0b000011": "地泽临",
  "0b000100": "地山谦",
  "0b000101": "地火明夷",
  "0b000110": "地风升",
  "0b000111": "地天泰",
  "0b001000": "雷地豫",
  "0b001001": "震为雷",
  "0b001010": "雷水解",
  "0b001011": "雷泽归妹",
  "0b001100": "雷山小过",
  "0b001101": "雷火丰",
  "0b001110": "雷风恒",
  "0b001111": "雷天大壮",
  "0b010000": "水地比",
  "0b010001": "水雷屯",
  "0b010010": "坎为水",
  "0b010011": "水泽节",
  "0b010100": "水山蹇",
  "0b010101": "水火既济",
  "0b010110": "水风井",
  "0b010111": "水天需",
  "0b011000": "泽地萃",
  "0b011001": "泽雷随",
  "0b011010": "泽水困",
  "0b011011": "兑为泽",
  "0b011100": "泽山咸",
  "0b011101": "泽火革",
  "0b011110": "泽风大过",
  "0b011111": "泽天夬",
  "0b100000": "山地剥",
  "0b100001": "山雷颐",
  "0b100010": "山水蒙",
  "0b100011": "山泽损",
  "0b100100": "艮为山",
  "0b100101": "山火贲",
  "0b100110": "山风蛊",
  "0b100111": "山天大畜",
  "0b101000": "火地晋",
  "0b101001": "火雷噬嗑",
  "0b101010": "火水未济",
  "0b101011": "火泽睽",
  "0b101100": "火山旅",
  "0b101101": "离为火",
  "0b101110": "火风鼎",
  "0b101111": "火天大有",
  "0b110000": "风地观",
  "0b110001": "风雷益",
  "0b110010": "风水涣",
  "0b110011": "风泽中孚",
  "0b110100": "风山渐",
  "0b110101": "风火家人",
  "0b110110": "巽为风",
  "0b110111": "风天小畜",
  "0b111000": "天地否",
  "0b111001": "天雷无妄",
  "0b111010": "天水讼",
  "0b111011": "天泽履",
  "0b111100": "天山遁",
  "0b111101": "天火同人",
  "0b111110": "天风姤",
  "0b111111": "乾为天",
}
  const JIEQI = ['春分', '清明', '谷雨', '立夏', '小满', '芒种', '夏至', '小暑', '大暑', '立秋', '处暑', '白露', '秋分', '寒露', '霜降', '立冬', '小雪', '大雪', '冬至', '小寒', '大寒', '立春', '雨水', '惊蛰'];
 
  const SHENXIAO = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];
  
  const dxs = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const dxy = ['正月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '冬月', '腊月'];
  const dxd = ['初一', '初二', '初三', '初四', '初五', '初六', '初七', '初八', '初九', '初十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十', '廿一', '廿二', '廿三', '廿四', '廿五', '廿六', '廿七', '廿八', '廿九', '三十'];
 
  const getObjectPros = (obj, propertyName) => obj[propertyName];
  export  {
    YY, // 阴阳
    WUXIN, // 五行
    FX, // 方位
    XT_BG, // 八卦
    XT_BG_VALUE_KEY, // key转换
    JIAZI, // 夹子
    TG, // 天干
    DZ, //地支
    SHISHEN, // 十神
    SHISHENJ, //  十神

    JIEQI, // 节气
    SHENXIAO, // 生效
    getObjectPros,

  };