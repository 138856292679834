import React from "react";
import {
  YY, // 阴阳
  WUXIN, // 五行
  FX, // 方位
  XT_BG, // 八卦
  JIAZI, // 夹子
  TG, // 天干
  DZ, //地支
  SHISHEN, // 十神
  SHISHENJ, //  十神
  JIEQI, // 节气
  SHENXIAO, // 生效
} from "../constants/index.js";
/*
interface LineProps {
  length: number;
  color?: string;
  height?: number;
  yang?: number;
}

interface GuaiProps {
  length: number;
  color?: string;
  height?: number;
  value: number[];
}

interface Guai2Props {
  length: number;
  color?: string;
  upColor?: string;
  bottomColor?: string;
  height?: number;
  up: number;
  bottom: number;
}
*/
const getProperty = (obj, propertyName) => obj[propertyName];

export const Line = ({ length, color, height, yang }) => {
  return (
    <svg width={length} height={height || 5}>
      {yang && (
        <line
          x1="0"
          y1="0"
          x2={length}
          y2="0"
          stroke={color || "#000"}
          strokeWidth={height || 5}
        />
      )}
      {!yang && (
        <>
          <line
            x1="0"
            y1="0"
            x2={length / 2 - length * 0.1}
            y2="0"
            stroke={color || "#000"}
            strokeWidth={height || 5}
          />
          <line
            x1={length / 2 + length * 0.1}
            y1="0"
            x2={length}
            y2="0"
            stroke={color || "#000"}
            strokeWidth={height || 5}
          />
        </>
      )}
    </svg>
  );
};

export const Guai = ({ length, color, height, value }) => {
  return (
    <>
      {value.map((item) => (
        <div className="line-Guai">
          <Line length={length} yang={item} height={height} color={color} />
        </div>
      ))}
    </>
  );
};

export const Guai2 = ({
  length,
  height,
  color,
  upColor,
  bottomColor,
  up,
  bottom,
}) => {
  const upobj = getProperty(XT_BG, `x${up}`);
  const bottomobj = getProperty(XT_BG, `x${bottom}`);
  return (
    <div className="line-Guai2">
      <Guai
        length={length}
        value={upobj.yyvalue}
        height={height}
        color={upColor || color || upobj.color}
      />
      <Guai
        length={length}
        value={bottomobj.yyvalue}
        height={height}
        color={bottomColor || color || bottomobj.color}
      />
    </div>
  );
};
