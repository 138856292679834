

import {
  getObjectPros,
  YY, // 阴阳
  WUXIN, // 五行
  FX, // 方位
  XT_BG, // 八卦
  XT_BG_VALUE_KEY,
  JIAZI, // 夹子
  TG, // 天干
  DZ, //地支
  SHISHEN, // 十神
  SHISHENJ, //  十神
  JIEQI, // 节气
  SHENXIAO, // 生效
} from "../constants/index.js";
import 
  guaG64
from "../constants/guaG64.json";


  const LineValueToGuaObj= ((yyvalues) =>{
     const strkey = yyvalues.join('');
     const key = getObjectPros(XT_BG_VALUE_KEY,`x${strkey}`);
     return  getObjectPros(XT_BG,key);
  });

  const LineValue2ToGuaObj= ((yyvalues) =>{
    const up = LineValueToGuaObj([yyvalues[0],yyvalues[1],yyvalues[2]])
    const bottom = LineValueToGuaObj([yyvalues[3],yyvalues[4],yyvalues[5]])
    return {up,bottom,yao:yyvalues.join('')}
 });

  const getLineValue= ((xuhao) =>{
    return getObjectPros(XT_BG,`x${xuhao}`).yyvalue;
 });

  const getLine2Values = ((up, bottom) =>{
    const values=[]
    values.push(...getLineValue(up));
    values.push(...getLineValue(bottom));
    return  values;
  });

  // 本卦 体卦
const benGuai = (up, bottom) =>{
  
  const values= getLine2Values(up,bottom);
  const benGuaiobj = LineValue2ToGuaObj(values)
  return benGuaiobj;
  // const upobj= getObjectPros(XT_BG,`x${up}`);
  // const bottomobj= getObjectPros(XT_BG,`x${bottom}`);
  // return {up:upobj, bottom:bottomobj};
 }
  // 互卦hu
const huGuai = (up, bottom) =>{
   const values= getLine2Values(up,bottom);
   const newvalue=[values[1],values[2],values[3],values[2],values[3],values[4]]
   const huGuaiobj = LineValue2ToGuaObj(newvalue)
 return huGuaiobj;
 }
// 错卦
const cuoGuai = (up, bottom) =>{
  const values= getLine2Values(up,bottom);
  const newvalues= values.map(item => {
     return item?0:1
   });
    
  const cuoGuaiobj = LineValue2ToGuaObj(newvalues)
  return cuoGuaiobj;
 }

 // 综卦
const zongGuai = (up, bottom) =>{
  const values= getLine2Values(up,bottom);
  const newvalue=[values[5],values[4],values[3],values[2],values[1],values[0]]
  const huGuaiobj = LineValue2ToGuaObj(newvalue)
return huGuaiobj;
 }

// 变卦
const bianGuai = (up, bottom,dongyao) =>{
 const values= getLine2Values(up,bottom);
 values[dongyao]=values[dongyao]?0:1;
 const bianGuaiobj = LineValue2ToGuaObj(values)
 return bianGuaiobj;
}
export const CreateAll =(up, bottom,dongyao) => {
 const  benGuaiobj = benGuai(up,bottom);
 const  huGuaiobj =huGuai(up,bottom);
 const  bianGuaiobj = bianGuai(up,bottom,dongyao);
 const  zongGuaiobj = zongGuai(up,bottom);
 const  cuoGuaiobj = cuoGuai(up,bottom);

 return {
  tiYong:dongyao>3?'up':'bottom',
  benGuai:{title:'本卦',guaiItems:benGuaiobj,guai:guaG64['x'+benGuaiobj.yao]},
  huGuai:{title:'互卦',guaiItems:huGuaiobj,guai:guaG64['x'+huGuaiobj.yao]},
  bianGuai:{title:'变卦',guaiItems:bianGuaiobj,guai:guaG64['x'+bianGuaiobj.yao]},
  zongGuai:{title:'综卦',guaiItems:zongGuaiobj,guai:guaG64['x'+zongGuaiobj.yao]}, 
  cuoGuai:{title:'错卦',guaiItems:cuoGuaiobj,guai:guaG64['x'+cuoGuaiobj.yao]},
 }
}