import { BoxOwnerState } from "@mui/joy/Box";
import React, { useState, useEffect } from "react";
import { Guai2, Guai } from "./components/YiJin";

import {
  FormLabel,
  Grid,
  Button,
  Input,
  FormHelperText,
  Typography,
  FormControl,
  Stack,
} from "@mui/joy";
import Divider from "@mui/joy/Divider";
import { CreateAll } from "./utils/meihua";
import InputTime, {
  SelectRadio,
  AutoCurTime,
  InputMeiHuaNum,
} from "./InputTime";
const gualist = ["benGuai", "huGuai", "bianGuai", "zongGuai", "cuoGuai"];

function MeiHua() {
  const Length = 200;
  const height = 20;
  const selectTypeList = [
    { label: "数字起卦", value: 1 },
    { label: "当前时间起卦", value: 2 },
    { label: "输入时间起卦", value: 3 },
  ];

  const color = "#000";
  const [selectType, setSelectType] = useState(1);
  // 当前时间
  const [curtime, setCurTime] = useState(new Date());

  const [time, setTime] = useState({
    year: 2020, // 1993
    month: 7, // 7
    day: 23, // 20
    hour: 4, // 22
    min: 0,
    sec: 0,
  });
  const [inputnums, setTnputnums] = useState([19, 48, 45]); // 输入的数字
  const [values, setValues] = useState([1,1,1]); // 上挂，下卦，动摇
  const [meihua, setMeihua] = useState({});

  useEffect(() => {
    const obj = CreateAll(values[0], values[1], values[3]);
    console.log(obj);
    setMeihua(obj);
  }, [values]);

  useEffect(() => {
    QiGuai();
  }, []);

  const QiGuai = () => {
    if (selectType == 1) {
      values[0] = inputnums[0] % 8;
      values[0] = values[0] ? values[0] : 8;

      values[1] = inputnums[1] % 8;
      values[1] = values[1] ? values[1] : 8;

      values[2] = inputnums[2] % 6;
      values[2] = values[2] ? values[2] : 8;
      setValues([...values]);
    } else if (selectType == 1) {
      setValues([...values]);
    } else if (selectType == 2) {
      setValues([...values]);
    }
  };

  // 爻数组得到卦对象
  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      <Grid xs={12} md={5} lg={4}>
        <SelectRadio
          type={selectType}
          setType={setSelectType}
          typeList={selectTypeList}
        ></SelectRadio>
      </Grid>
      {selectType == 3 && (
        <Grid xs={12}  md={7}  lg={6}>
          <InputTime time={time} setTime={setTime} min sec></InputTime>
        </Grid>
      )}
      {selectType == 2 && (
        <Grid xs={12}   md={7}  lg={6}>
          <AutoCurTime curtime={curtime} setCurTime={setCurTime}></AutoCurTime>
        </Grid>
      )}
      {selectType == 1 && (
        <Grid xs={12}  md={7}  lg={6}>
          <InputMeiHuaNum
            nums={inputnums}
            setNums={setTnputnums}
          ></InputMeiHuaNum>
        </Grid>
      )}
      <Grid xs={12}  lg={2}>
        <Button sx={{ width: 150 }} onClick={QiGuai} size="lg">
          开始起卦
        </Button>
      </Grid>

      {/* <Guai length={Length} height={20} value={[1, 0, 1]} color={"red"} /> */}

      <Grid xs={12}>
        {gualist.map((item, i) => {
          return (
            meihua[item] && (
              <>
                <Stack spacing={2} direction="row">
                  <Stack spacing={2} justifyContent="top" alignItems="center">
                    <Stack spacing={2}>
                      <Typography>
                        {meihua[item].title}. ({meihua[item].guai.jname}.
                        {meihua[item].guai.name})
                      </Typography>
                    </Stack>
                    <Guai2
                      length={Length}
                      height={height}
                      up={meihua[item].guaiItems.bottom.xuhao}
                      bottom={meihua[item].guaiItems.up.xuhao}
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <Stack spacing={2}>
                      <Typography>
                        {meihua[item].guai.jixiong} {"卦序:"}
                        {meihua[item].guai.xuhao} {"卦象:"}
                        {meihua[item].guai.yao}
                      </Typography>
                    </Stack>
                    <Stack spacing={2}>
                      <Typography level="body2">
                        {meihua[item].guai.xiang}
                      </Typography>
                    </Stack>
                    <Stack spacing={2}>
                      <Typography level="body2">
                        {meihua[item].guai.xiang2}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Divider />
                </Stack>
              </>
            )
          );
        })}
      </Grid>
    </Grid>
  );
}

export default MeiHua;
